export function searchQuestions(value, questions) {
    if (value !== '' && questions) {
        return questions.filter((question) => question.headers.toLowerCase().includes(value.toLowerCase()));
    } else {
        if (questions) {
            return questions;
        }
    }

    return [];
}

export function searchScales(value, scales) {
    if (value !== '' && scales) {
        return scales.filter((scale) => scale.name.toLowerCase().includes(value.toLowerCase()));
    } else {
        if (scales) {
            return scales;
        }
    }

    return [];
}

export function getColors(index, questionsScales) {
    const colors = [];

    if (questionsScales) {
        if (questionsScales[index]) {
            if (questionsScales[index].levels) {
                questionsScales[index].levels.forEach((level) => {
                    colors.push(level.color);
                });
            }
        }
    }

    return colors;
}

export function getQuestionOrScaleIndex(list, id) {
    if (list) {
        const finedIndex = list.findIndex((item) => item._id === id);

        if (finedIndex !== -1) {
            return finedIndex;
        }
    }

    return null;
}
