import { create } from 'zustand';

const initialState = {
    localChanges: {},
};

export const useTempLocalChanges = create((set) => ({
    ...initialState,
    updateLocaleChanges: (newLocalChanges) =>
        set((state) => ({ localChanges: { ...state.localChanges, ...newLocalChanges } })),
    resetLocaleChanges: () => set({ ...initialState }),
}));

export const selectLocalChanges = (state) => state.localChanges;

export const selectUpdateLocaleChanges = (state) => state.updateLocaleChanges;

export const selectResetLocaleChanges = (state) => state.resetLocaleChanges;
