import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { QuestionTestCreationLeftMenu } from './QuestionTestCreation/QuestionTestCreationLeftMenu';
import { QuestionTestCreationRightMenu } from './QuestionTestCreation/QuestionTestCreationRightMenu';

export function QuestionTestCreation() {
    const { testId } = useParams();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    useEffect(() => {
        setCurrentQuestionIndex(0);
    }, [testId]);

    return (
        <div className="row questions">
            <QuestionTestCreationLeftMenu
                currentQuestionIndex={currentQuestionIndex}
                changeCurrentQuestionIndex={setCurrentQuestionIndex}
            />
            <QuestionTestCreationRightMenu
                currentQuestionIndex={currentQuestionIndex}
                changeCurrentQuestionIndex={setCurrentQuestionIndex}
            />
        </div>
    );
}
