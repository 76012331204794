import React from 'react';

import { useSelector } from 'react-redux';
import { TestTitleInput } from 'pages/TestCreation/atoms/TestTitleInput';
import { TestMethodAuthorInput } from 'pages/TestCreation/atoms/TestMethodAuthorInput';
import { TestTextField } from 'pages/TestCreation/atoms/TestTextField';
import { DropdownAgeInput } from 'pages/TestCreation/atoms/DropdownAgeInput';
import { DropdownTags } from 'pages/TestCreation/atoms/DropdownTags';
import { DropdownDirections } from 'pages/TestCreation/atoms/DropdownDirections';
import { ReactComponent as QuestionIcon } from 'assets/img/question.svg';

export function GeneralTestCreationRightMenu() {
    const { currentTest, staticTags, staticDirections } = useSelector((state) => state.main);

    return (
        <div className="rightMenu">
            <div className="innerCreation">
                <TestTitleInput
                    value={currentTest.name}
                    currentTestId={currentTest._id}
                    autosave={currentTest.autosave}
                />
                <TestMethodAuthorInput
                    value={currentTest.methodAuthor}
                    currentTestId={currentTest._id}
                    autosave={currentTest.autosave}
                />
                <TestTextField
                    currentTestId={currentTest._id}
                    value={currentTest.desTeacher}
                    field="desTeacher"
                    label="Описание для специалистов  (не отображается у обучающихся)"
                    questionText="Напишите, какую информацию позволит узнать тестирование, для кого и с какой целью он может быть использован. Укажите, сколько вопросов содержит тест и какая шкала используется для выведения результата"
                    autosave={currentTest.autosave}
                />
                {/* <TestTextField
                    currentTestId={currentTest._id}
                    value={currentTest.desStudent}
                    field="desStudent"
                    label="Описание для ученика (не обязательное поле)"
                    autosave={currentTest.autosave}
                /> */}
                <TestTextField
                    currentTestId={currentTest._id}
                    value={currentTest.desStudents}
                    field="desStudents"
                    label="Инструкция для ученика"
                    questionText="Пропишите правило внесения ответов на задания: необходимость выбрать цифру или вариант “да” или “нет”, чтобы отразить свое согласие или несогласие с предлагаемым в тесте утверждением."
                    autosave={currentTest.autosave}
                />

                <div className="scaleContent ages-wrapper">
                    <div className="row">
                        <div className="ages-label block-with-tooltip">
                            <span>
                                Возраст{' '}
                                <QuestionIcon
                                    data-tooltip-id="react-tooltip"
                                    data-tooltip-content="Укажите возраст ученика, которому рекомендуется прохождение данного теста."
                                    data-tooltip-variant="dark"
                                />
                            </span>
                        </div>
                        <DropdownAgeInput currentTest={currentTest} />
                    </div>
                </div>

                <DropdownTags
                    id="test_tags"
                    type=""
                    value=""
                    currentTest={currentTest}
                    tags={currentTest.tags}
                    staticTags={staticTags}
                    label="Добавить теги"
                />

                {staticDirections?.length && (
                    <DropdownDirections
                        currentTest={currentTest}
                        directions={currentTest.directions}
                        staticDirections={staticDirections}
                    />
                )}
            </div>
        </div>
    );
}
