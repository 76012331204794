import { combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { composeWithDevTools } from '@redux-devtools/extension';
import mainReducer from './reducers/mainReducer';
import { userReducer } from './reducers/userReducer';
import { alertReducer } from './reducers/alertReducer';

const rootReducer = combineReducers({
    main: mainReducer,
    user: userReducer,
    alert: alertReducer,
});

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);
