import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import { selectTestFilling, useTestFilling } from 'store/useTestFilling';
import infoError from 'pages/TestCreation/img/infoError.svg';

export default function CreationTabBar(props) {
    let currentTest = useSelector((state) => state.main.currentTest);
    let location = useLocation();
    let parts = location.pathname.split('/');
    const testFilling = useTestFilling(selectTestFilling);

    function returnLink(window) {
        let newLink = '';
        if (window === 0) {
            if (parts.length === 3) {
                newLink = '/test/' + parts[parts.length - 1];
            }
            if (parts.length === 4) {
                newLink = '/test/' + parts[parts.length - 2];
            }
        }
        if (window === 1) {
            if (parts.length === 3) {
                newLink = '/test/' + parts[parts.length - 1] + '/scales';
            }
            if (parts.length === 4) {
                newLink = '/test/' + parts[parts.length - 2] + '/scales';
            }
        }
        if (window === 2) {
            if (parts.length === 3) {
                newLink = '/test/' + parts[parts.length - 1] + '/questions';
            }
            if (parts.length === 4) {
                newLink = '/test/' + parts[parts.length - 2] + '/questions';
            }
        }
        return newLink;
    }

    return (
        <div className="creationBar">
            <button
                onClick={() => {
                    props.setCurrentWindow(0);
                    window.history.pushState('', '', returnLink(0));
                }}
                className={cn('creationBarButton', {
                    active: props.currentWindow === 0,
                })}
            >
                <div className="creationBarButtonFlex">
                    Основное
                    {testFilling.main && (
                        <img
                            className="infoDot"
                            src={infoError}
                            alt=""
                            data-tooltip-id="react-tooltip"
                            data-tooltip-content="Есть незаполненные поля"
                            data-tooltip-variant="error"
                        />
                    )}
                </div>
            </button>
            <button
                onClick={() => {
                    props.setCurrentWindow(1);
                    window.history.pushState('', '', returnLink(1));
                }}
                className={cn('creationBarButton', {
                    active: props.currentWindow === 1,
                })}
            >
                <div className="creationBarButtonFlex">
                    Шкалы
                    <span>{currentTest.scales ? currentTest.scales.length : 0}</span>
                    {!!testFilling.scales.length && (
                        <img
                            className="infoDot"
                            src={infoError}
                            alt=""
                            data-tooltip-id="react-tooltip"
                            data-tooltip-content="Есть незаполненные поля"
                            data-tooltip-variant="error"
                        />
                    )}
                </div>
            </button>
            <button
                onClick={() => {
                    props.setCurrentWindow(2);
                    window.history.pushState('', '', returnLink(2));
                }}
                className={cn('creationBarButton', {
                    active: props.currentWindow === 2,
                })}
            >
                <div className="creationBarButtonFlex">
                    Вопросы<span>{currentTest.questions ? currentTest.questions.length : 0}</span>
                </div>
            </button>
        </div>
    );
}
