function isRangeValid(range) {
    if (!range) {
        return false;
    }

    const isFromValid = range.from !== undefined && range.from !== null;
    const isToValid = range.to !== undefined && range.to !== null;

    return isFromValid && isToValid;
}

export const checkTestFilling = (test) => {
    const { name, methodAuthor, desTeacher, desStudents, scales } = test;
    const result = {
        main: false,
        scales: [],
    };

    if (!name || !methodAuthor || !desTeacher || !desStudents) {
        result.main = true;
    }

    for (const scale of scales) {
        const { _id, name, description, levels } = scale;

        if (!name || !description) {
            result.scales.push(_id);
            continue;
        }

        for (const level of levels) {
            if (result.scales.includes(_id)) continue;

            const { color, interpretation, recommendation, range } = level;

            if (!color || !interpretation || !recommendation || !isRangeValid(range)) {
                result.scales.push(_id);
            }
        }
    }

    return result;
};
