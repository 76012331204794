import React from "react";
import arrowDown from '../img/arrowDown.svg'
import {Link, useLocation,} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    createTest,
    fetchQuestions,
    fetchScales, fetchScaleSettings, fetchStaticTags,
    fetchTests,
    setCurrentTestById
} from "../../../Redux/reducers/mainReducer";
import {showAlert} from '../../../Redux/actions/alertActions'

export default function DropdownHeaderButtons(props){

        let dispatch = useDispatch();
        const location = useLocation();
        let tests = useSelector(state => state.main.tests)
        let currentTest = useSelector(state => state.main.currentTest)
        let isLogged = useSelector(state => state.main.isLogged)
        function getRecentTests(array){
            let sortedArray = array.sort(function(a,b){
                return new Date(b) - new Date(a);
            });
            return sortedArray.slice(0, 3)
        }
        let recentTests;
        if(isLogged){
            if(tests){
                if(tests.length !== 0){
                    recentTests = getRecentTests(tests)
                }
            }

        }
    let tagsLimiter = 0;
        function changeLocalTest(id){
            if(!currentTest._id){
                dispatch(fetchTests());
            }
            setTimeout(()=>{
                if(window.location.pathname !== '/test'){
                    let id = window.location.pathname.slice(6)
                    dispatch(setCurrentTestById(id))
                    if(id){
                        dispatch(fetchScales(id))
                        dispatch(fetchQuestions(id))
                        dispatch(fetchScaleSettings(id))

                    }
                    dispatch(fetchStaticTags());
                }
            }, 200)
        }

        const showInstruction = () => {
          dispatch(showAlert({ type: 'info', text: "Обратитесь на почту school@testu.online для получения инструкции" }))
        }

        return (
                <div className="inner">
                    {isLogged ? <div className="dropdownHeaderButton">
                        <button className={location.pathname === '/test/' + currentTest._id || location.pathname === '/tests' || location.pathname === '/tests/published'  ? 'active' : ''}>
                            Тесты
                            <img alt="" src={arrowDown}/>
                        </button>
                        <div className="dropContent long">
                            {recentTests ? recentTests.length > 0 ?
                                <div className="dropContentButton recentTests">
                                    Недавние тесты
                                </div>
                                :'' : ''}

                            {recentTests ? recentTests.map((item, index2) => {
                                if(item.tags){
                                    return(
                                        <Link key={index2} to={"/test/" + item._id}><div onClick={()=>{changeLocalTest(item._id)}} className="dropContentButton recent">
                                            {item.name}
                                            <div className="row">
                                                {item.tags.map((tag, index3) => {

                                                    return <div key={index3} style={{backgroundColor: '#BCFFE7'}} className="tag">
                                                        {tag.name}
                                                    </div>
                                                })}
                                            </div>
                                        </div></Link>
                                    )
                                } else {
                                    return(
                                        <div key={index2} className="dropContentButton recent">
                                            {item.name}
                                        </div>
                                    )
                                }

                            }) : ''}
                            <Link to="/tests">
                                <div className="dropContentButton">
                                    Показать все тесты
                                </div>
                            </Link>

                                <div onClick={() => {dispatch(createTest())}} className="dropContentButton">
                                    Создать тест
                                </div>

                        </div>
                    </div> : ''}

                    <div className="dropdownHeaderButton">
                        <button>
                            Помощь
                            <img  alt="" src={arrowDown}/>
                        </button>
                        <div className="dropContent">
                            <div className="dropContentButton instructionButton">
                                <button onClick={showInstruction}>
                                  Инструкция
                                </button>
                            </div>
                            {/*<div className="dropContentButton">*/}
                            {/*    Написать нам*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>









            )





}