import { getCookie } from '../../helpers/cookies';

let LOAD_TESTS = 'LOAD_TESTS';
let SET_CURRENT_TEST = 'SET_CURRENT_TEST';
let SET_CURRENT_TEST_BY_ID = 'SET_CURRENT_TEST_BY_ID';
let CHANGE_LOCAL_TEST_NAME = 'CHANGE_LOCAL_TEST_NAME';
let CHANGE_LOCAL_STUDENT_DESCRIPTION = 'CHANGE_LOCAL_STUDENT_DESCRIPTION';
let CHANGE_LOCAL_OPEN_PSYCHOLOGIST = 'CHANGE_LOCAL_OPEN_PSYCHOLOGIST';
let CHANGE_LOCAL_OPEN_ONLINE = 'CHANGE_LOCAL_OPEN_ONLINE';
let LOAD_SCALES = 'LOAD_SCALES';
let CHANGE_LOCAL_SPECIALIST_DESCRIPTION = 'CHANGE_LOCAL_SPECIALIST_DESCRIPTION';
let CHANGE_LOCAL_STUDENT_INSTRUCTION = 'CHANGE_LOCAL_STUDENT_INSTRUCTION';
let LOAD_STATIC_TAGS = 'LOAD_STATIC_TAGS';
let LOAD_STATIC_DIRECTIONS = 'LOAD_STATIC_DIRECTIONS';
let CHANGE_LOCAL_DIRECTIONS = 'CHANGE_LOCAL_DIRECTIONS';
let CHANGE_LOCAL_TAGS = 'CHANGE_LOCAL_TAGS';
let CHANGE_LOCAL_AGES = 'CHANGE_LOCAL_AGES';
let LOAD_QUESTIONS = 'LOAD_QUESTIONS';
let CHANGE_LOCAL_LINEAR_FROM = 'CHANGE_LOCAL_LINEAR_FROM';
let CHANGE_LOCAL_LINEAR_TO = 'CHANGE_LOCAL_LINEAR_TO';
let CHANGE_LOCAL_QUESTION_NAME = 'CHANGE_LOCAL_QUESTION_NAME';
let CHANGE_LOCAL_QUESTION_IMAGE = 'CHANGE_LOCAL_QUESTION_IMAGE';
let CHANGE_LOCAL_SCALE_DESCRIPTION = 'CHANGE_LOCAL_SCALE_DESCRIPTION';
let CHANGE_LOCAL_SCALE_NAME = 'CHANGE_LOCAL_SCALE_NAME';
let CHANGE_LOCAL_SCALE_RECOMMENDATION = 'CHANGE_LOCAL_SCALE_RECOMMENDATION';
let CHANGE_LOCAL_SCALE_INTERPRETATION = 'CHANGE_LOCAL_SCALE_INTERPRETATION';
let CHANGE_LOCAL_SCALE_FROM = 'CHANGE_LOCAL_SCALE_FROM';
let CHANGE_LOCAL_SCALE_TO = 'CHANGE_LOCAL_SCALE_TO';
let CHANGE_LOCAL_SCALE_COLOR = 'CHANGE_LOCAL_SCALE_COLOR';
let LOAD_SCALE_SETTINGS = 'LOAD_SCALE_SETTINGS';
let CHANGE_LOCAL_SETTINGS_VALUE = 'CHANGE_LOCAL_SETTINGS_VALUE';
let CHANGE_LOCAL_TYPE_SCALE = 'CHANGE_LOCAL_TYPE_SCALE';
let START_LOCAL_QUESTION_SCALES = 'START_LOCAL_QUESTION_SCALES';
let UPDATE_LOCAL_QUESTION_SCALES = 'UPDATE_LOCAL_QUESTION_SCALES';
let CHANGE_LOCAL_QUESTION_VALUE = 'CHANGE_LOCAL_QUESTION_VALUE';
// let CHANGE_LOCAL_QUESTION_QUESTION = 'CHANGE_LOCAL_QUESTION_QUESTION';
let CHANGE_LOCAL_QUESTION_ANSWER = 'CHANGE_LOCAL_QUESTION_ANSWER';
let CHANGE_LOCAL_IS_LOGGED = 'CHANGE_LOCAL_IS_LOGGED';
let INIT_LOCAL_SCALES_TO_ERRORS = 'INIT_LOCAL_SCALES_TO_ERRORS';
let CHANGE_LOCAL_SCALE_ERROR_STATE = 'CHANGE_LOCAL_SCALE_ERROR_STATE';
let CHANGE_LOCAL_SCALE_ERROR_MESSAGE = 'CHANGE_LOCAL_SCALE_ERROR_MESSAGE';
let SHOW_BANNER = 'SHOW_BANNER';
let HIDE_BANNER = 'HIDE_BANNER';
let CHANGE_LOCAL_INTEGRAL = 'CHANGE_LOCAL_INTEGRAL';
let SET_ISLOADED_TESTS = 'SET_ISLOADED_TESTS';
let SET_ISLOADED_QUESTIONS = 'SET_ISLOADED_QUESTIONS';
let SET_ISLOADED_SCALES = 'SET_ISLOADED_SCALES';
let LOAD_COUNTING_TYPES = 'LOAD_COUNTING_TYPES';
let CHANGE_LOCAL_COUNTING_TYPE = 'CHANGE_LOCAL_COUNTING_TYPE';
let CHANGE_LOCAL_PUBLIC = 'CHANGE_LOCAL_PUBLIC';
let CHANGE_LOCAL_METHOD_NAME = 'CHANGE_LOCAL_METHOD_NAME';
let CHANGE_LOCAL_METHOD_AUTHOR = 'CHANGE_LOCAL_METHOD_AUTHOR';
let CHANGE_LOCAL_QUESTION_QUANTITY = 'CHANGE_LOCAL_QUESTION_QUANTITY';

const CHANGE_LOCAL_FIELDS = 'CHANGE_LOCAL_FIELDS';

let defaultState = {
    tests: [],
    staticTags: [],
    currentTest: {
        scales: [],
        questions: [],
        settings: {},
    },
    countingTypes: [],
    isLogged: false,
    isTestsLoaded: false,
    isScalesLoaded: false,
    isQuestionsLoaded: false,
};
export default function mainReducer(state = defaultState, action) {
    switch (action.type) {
        case CHANGE_LOCAL_COUNTING_TYPE:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    settings: {
                        ...state.currentTest.settings,
                        [0]: {
                            ...state.currentTest.settings[0],
                            countingMethodId: action.payload,
                        },
                    },
                },
            };
        case SET_ISLOADED_TESTS:
            return {
                ...state,
                isTestsLoaded: true,
            };
        case SET_ISLOADED_SCALES:
            return {
                ...state,
                isTestsLoaded: true,
            };
        case SET_ISLOADED_QUESTIONS:
            return {
                ...state,
                isTestsLoaded: true,
            };
        case CHANGE_LOCAL_INTEGRAL:
            let newIntegral = state.currentTest.questions[action.payload[0]];
            newIntegral.integral = action.payload[1];
            let newIntegralArray = [
                ...state.currentTest.questions.slice(0, action.payload[0]),
                newIntegral,
                ...state.currentTest.questions.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    questions: newIntegralArray,
                },
            };
        case SHOW_BANNER:
            return {
                ...state,
                showBanner: true,
            };
        case HIDE_BANNER:
            return {
                ...state,
                showBanner: false,
            };
        case CHANGE_LOCAL_IS_LOGGED:
            return {
                ...state,
                isLogged: action.payload,
            };
        case CHANGE_LOCAL_OPEN_ONLINE:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    openOnline: action.payload,
                },
            };
        case CHANGE_LOCAL_PUBLIC:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    public: action.payload,
                },
            };
        case CHANGE_LOCAL_OPEN_PSYCHOLOGIST:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    openPsychologist: action.payload,
                },
            };
        case CHANGE_LOCAL_TEST_NAME:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    name: action.payload,
                },
            };
        case CHANGE_LOCAL_METHOD_NAME:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    methodName: action.payload,
                },
            };
        case CHANGE_LOCAL_METHOD_AUTHOR:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    methodAuthor: action.payload,
                },
            };
        case CHANGE_LOCAL_STUDENT_DESCRIPTION:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    desStudent: action.payload,
                },
            };
        case CHANGE_LOCAL_STUDENT_INSTRUCTION:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    desStudents: action.payload,
                },
            };
        case CHANGE_LOCAL_SPECIALIST_DESCRIPTION:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    desTeacher: action.payload,
                },
            };
        case CHANGE_LOCAL_FIELDS:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    ...action.payload,
                },
            };
        case CHANGE_LOCAL_LINEAR_FROM:
            let newFromQuestion = state.currentTest.questions[action.payload[0]];
            newFromQuestion.range.from = action.payload[1];
            let newFrom = [
                ...state.currentTest.questions.slice(0, action.payload[0]),
                newFromQuestion,
                ...state.currentTest.questions.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    questions: newFrom,
                },
            };
        case CHANGE_LOCAL_LINEAR_TO:
            let newToQuestion = state.currentTest.questions[action.payload[0]];
            newToQuestion.range.to = action.payload[1];
            let newTo = [
                ...state.currentTest.questions.slice(0, action.payload[0]),
                newToQuestion,
                ...state.currentTest.questions.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    questions: newTo,
                },
            };
        case CHANGE_LOCAL_QUESTION_NAME:
            let newQuestionName = state.currentTest.questions[action.payload[0]];
            newQuestionName.headers = action.payload[1];
            let newQuestions = [
                ...state.currentTest.questions.slice(0, action.payload[0]),
                newQuestionName,
                ...state.currentTest.questions.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    questions: newQuestions,
                },
            };
        case CHANGE_LOCAL_QUESTION_IMAGE: {
            const { id, image } = action.payload;
            const index = state.currentTest.questions.findIndex(({ _id }) => _id === id);

            if (index === -1) {
                return state;
            } else {
                const newQuestions = [...state.currentTest.questions];
                newQuestions[index].image = image;

                return {
                    ...state,
                    currentTest: {
                        ...state.currentTest,
                        questions: newQuestions,
                    },
                };
            }
        }
        case CHANGE_LOCAL_QUESTION_QUANTITY:
            let newQuestionQuantity = state.currentTest.questions[action.payload[0]];
            newQuestionQuantity.answerQuantity = action.payload[1];
            let newQuestionsQ = [
                ...state.currentTest.questions.slice(0, action.payload[0]),
                newQuestionQuantity,
                ...state.currentTest.questions.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    questions: newQuestionsQ,
                },
            };
        case CHANGE_LOCAL_SCALE_NAME:
            let newScaleName = state.currentTest.scales[action.payload[0]];
            newScaleName.name = action.payload[1];
            let newScales = [
                ...state.currentTest.scales.slice(0, action.payload[0]),
                newScaleName,
                ...state.currentTest.scales.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    scales: newScales,
                },
            };
        case CHANGE_LOCAL_SCALE_RECOMMENDATION:
            let newScaleRecommendation = state.currentTest.scales[action.payload[0]];
            newScaleRecommendation.levels[action.payload[1]].recommendation = action.payload[2];
            let newScalesR = [
                ...state.currentTest.scales.slice(0, action.payload[0]),
                newScaleRecommendation,
                ...state.currentTest.scales.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    scales: newScalesR,
                },
            };
        case CHANGE_LOCAL_SCALE_INTERPRETATION:
            let newScaleInterpretation = state.currentTest.scales[action.payload[0]];
            newScaleInterpretation.levels[action.payload[1]].interpretation = action.payload[2];
            let newScalesI = [
                ...state.currentTest.scales.slice(0, action.payload[0]),
                newScaleInterpretation,
                ...state.currentTest.scales.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    scales: newScalesI,
                },
            };
        case CHANGE_LOCAL_SCALE_DESCRIPTION:
            let newScaleDescription = state.currentTest.scales[action.payload[0]];
            newScaleDescription.description = action.payload[1];
            let newScalesDesc = [
                ...state.currentTest.scales.slice(0, action.payload[0]),
                newScaleDescription,
                ...state.currentTest.scales.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    scales: newScalesDesc,
                },
            };
        case CHANGE_LOCAL_SCALE_COLOR:
            let newScaleColor = state.currentTest.scales[action.payload[0]];
            newScaleColor.levels[action.payload[1]].color = action.payload[2];
            let newScalesUpdatedColor = [
                ...state.currentTest.scales.slice(0, action.payload[0]),
                newScaleColor,
                ...state.currentTest.scales.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    scales: newScalesUpdatedColor,
                },
            };
        case CHANGE_LOCAL_SCALE_TO:
            let newScaleTo = state.currentTest.scales[action.payload[0]];
            newScaleTo.levels[action.payload[1]].range.to = action.payload[2];
            let newScalesUpdatedTo = [
                ...state.currentTest.scales.slice(0, action.payload[0]),
                newScaleTo,
                ...state.currentTest.scales.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    scales: newScalesUpdatedTo,
                },
            };
        case CHANGE_LOCAL_SETTINGS_VALUE:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    settings: {
                        ...state.currentTest.settings,
                        [0]: {
                            ...state.currentTest.settings[0],
                            level: action.payload,
                        },
                    },
                },
            };
        case CHANGE_LOCAL_SCALE_FROM:
            let newScaleFrom = state.currentTest.scales[action.payload[0]];
            newScaleFrom.levels[action.payload[1]].range.from = action.payload[2];
            let newScalesUpdatedFrom = [
                ...state.currentTest.scales.slice(0, action.payload[0]),
                newScaleFrom,
                ...state.currentTest.scales.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    scales: newScalesUpdatedFrom,
                },
            };
        case CHANGE_LOCAL_TYPE_SCALE:
            let newQuestionTypeScale = state.currentTest.questions[action.payload[0]];
            newQuestionTypeScale.typeScale = action.payload[1];
            let newQuestionsTypeScales = [
                ...state.currentTest.questions.slice(0, action.payload[0]),
                newQuestionTypeScale,
                ...state.currentTest.questions.slice(action.payload[0] + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    questions: newQuestionsTypeScales,
                },
            };
        case START_LOCAL_QUESTION_SCALES:
            let newQuestionScales = [];
            if (state.currentTest.questions) {
                for (let i = 0; i < state.currentTest.questions.length; i++) {
                    if (state.currentTest.questions[i].typeScale) {
                        let toAdd = {};
                        for (let j = 0; j < state.currentTest.scales.length; j++) {
                            if (state.currentTest.questions[i].typeScale === state.currentTest.scales[j]._id) {
                                toAdd = state.currentTest.scales[j];
                            }
                        }
                        if (toAdd) {
                            newQuestionScales.push(toAdd);
                        } else {
                            newQuestionScales.push({});
                        }
                    }
                }
            }

            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    questionsScales: newQuestionScales,
                },
            };
        case UPDATE_LOCAL_QUESTION_SCALES:
            let updatedQuestionScales = state.currentTest.questionsScales;
            if (state.currentTest.questions) {
                for (let i = 0; i < state.currentTest.questions.length; i++) {
                    if (state.currentTest.questions[i].typeScale) {
                        for (let j = 0; j < state.currentTest.scales.length; j++) {
                            if (state.currentTest.questions[i].typeScale === state.currentTest.scales[j]._id) {
                                updatedQuestionScales[i] = state.currentTest.scales[j];
                            } else {
                            }
                        }
                    }
                }
            }
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    questionsScales: updatedQuestionScales,
                },
            };
        case CHANGE_LOCAL_QUESTION_VALUE: {
            const { questionInd, answerInd, value, additionalScaleId } = action.payload;
            const questions = [...state.currentTest.questions];

            if (additionalScaleId !== null) {
                questions[questionInd].selection[answerInd].additionalScales[additionalScaleId].value = value;
            } else {
                questions[questionInd].selection[answerInd].value = value;
            }
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    questions,
                },
            };
        }
        // case CHANGE_LOCAL_QUESTION_QUESTION:
        //     let newQuestionQuestion = state.currentTest.questions[action.payload[0]];
        //     newQuestionQuestion.selection[action.payload[1]].question = action.payload[2];
        //     let newQuestionQuestionArray = [
        //         ...state.currentTest.questions.slice(0, action.payload[0]),
        //         newQuestionQuestion,
        //         ...state.currentTest.questions.slice(action.payload[0] + 1),
        //     ];
        //     return {
        //         ...state,
        //         currentTest: {
        //             ...state.currentTest,
        //             questions: newQuestionQuestionArray,
        //         },
        //     };
        case CHANGE_LOCAL_QUESTION_ANSWER: {
            const { id1, id2, field, value } = action.payload;

            const newQuestionAnswer = state.currentTest.questions[id1];

            newQuestionAnswer.selection[id2][field] = value;
            const newQuestionQuestionArray = [
                ...state.currentTest.questions.slice(0, id1),
                newQuestionAnswer,
                ...state.currentTest.questions.slice(id1 + 1),
            ];
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    questions: newQuestionQuestionArray,
                },
            };
        }
        case INIT_LOCAL_SCALES_TO_ERRORS:
            let array = [];
            if (state.currentTest) {
                if (state.currentTest.scales) {
                    if (state.currentTest.scales.length > 0) {
                        state.currentTest.scales.map((scale, id1) => {
                            let scaleArray = [];
                            scale.levels.map((level, id2) => {
                                scaleArray.push({
                                    from: {
                                        error: false,
                                        message: '',
                                    },
                                    to: {
                                        error: false,
                                        message: '',
                                    },
                                });
                            });
                            array.push(scaleArray);
                        });
                    }
                }
            }

            return {
                ...state,
                local_scale_errors: array,
            };
        case CHANGE_LOCAL_SCALE_ERROR_STATE:
            let local_scales = state.local_scale_errors;
            if (local_scales) {
                if (action.payload[2] === 'to') {
                    local_scales[action.payload[0]][action.payload[1]].to.error = action.payload[3];
                }
                if (action.payload[2] === 'from') {
                    local_scales[action.payload[0]][action.payload[1]].from.error = action.payload[3];
                }
            }
            return {
                ...state,
                local_scale_errors: local_scales,
            };
        case CHANGE_LOCAL_SCALE_ERROR_MESSAGE:
            let local_scales_for_messages = state.local_scale_errors;
            if (local_scales_for_messages) {
                if (action.payload[2] === 'to') {
                    local_scales_for_messages[action.payload[0]][action.payload[1]].to.message = action.payload[3];
                }
                if (action.payload[2] === 'from') {
                    local_scales_for_messages[action.payload[0]][action.payload[1]].from.message = action.payload[3];
                }
            }
            return {
                ...state,
                local_scale_errors: local_scales_for_messages,
            };
        case SET_CURRENT_TEST_BY_ID:
            for (let i = 0; i < state.tests.length; i++) {
                if (state.tests[i]._id === action.payload) {
                    return {
                        ...state,
                        currentTest: state.tests[i],
                    };
                }
            }
            return state;
        case SET_CURRENT_TEST:
            return {
                ...state,
                currentTest: action.payload,
            };
        case LOAD_TESTS:
            return {
                ...state,
                tests: action.payload,
            };
        case LOAD_SCALES:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    scales: action.payload,
                },
            };
        case LOAD_SCALE_SETTINGS:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    settings: action.payload,
                },
            };
        case LOAD_COUNTING_TYPES:
            return {
                ...state,
                countingTypes: action.payload,
            };
        case LOAD_QUESTIONS:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    questions: action.payload,
                },
            };
        case LOAD_STATIC_DIRECTIONS:
            return {
                ...state,
                staticDirections: action.payload,
            };
        case LOAD_STATIC_TAGS:
            return {
                ...state,
                staticTags: action.payload,
            };
        case CHANGE_LOCAL_DIRECTIONS:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    directions: action.payload,
                },
            };
        case CHANGE_LOCAL_TAGS:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    tags: action.payload,
                },
            };
        case CHANGE_LOCAL_AGES:
            return {
                ...state,
                currentTest: {
                    ...state.currentTest,
                    ages: action.payload,
                },
            };
        default:
            return state;
    }
}

export const changeLocalTestName = (name) => ({
    type: CHANGE_LOCAL_TEST_NAME,
    payload: name,
});
export const changeLocalMethodName = (name) => ({
    type: CHANGE_LOCAL_METHOD_NAME,
    payload: name,
});
export const changeLocalMethodAuthor = (name) => ({
    type: CHANGE_LOCAL_METHOD_AUTHOR,
    payload: name,
});
export const changeLocalStudentDescription = (description) => ({
    type: CHANGE_LOCAL_STUDENT_DESCRIPTION,
    payload: description,
});
export const changeLocalStudentInstruction = (description) => ({
    type: CHANGE_LOCAL_STUDENT_INSTRUCTION,
    payload: description,
});
export const changeLocalSpecialistDescription = (description) => ({
    type: CHANGE_LOCAL_SPECIALIST_DESCRIPTION,
    payload: description,
});
export const changeLocalOpenOnline = (data) => ({
    type: CHANGE_LOCAL_OPEN_ONLINE,
    payload: data,
});
export const changeLocalPublic = (data) => ({
    type: CHANGE_LOCAL_PUBLIC,
    payload: data,
});
export const changeLocalOpenPsychologist = (data) => ({
    type: CHANGE_LOCAL_OPEN_PSYCHOLOGIST,
    payload: data,
});
export const changeLocalDirections = (data) => ({
    type: CHANGE_LOCAL_DIRECTIONS,
    payload: data,
});
export const changeLocalTags = (data) => ({
    type: CHANGE_LOCAL_TAGS,
    payload: data,
});
export const changeLocalAges = (data) => ({
    type: CHANGE_LOCAL_AGES,
    payload: data,
});
export const changeLocalLinearFrom = (id, from) => ({
    type: CHANGE_LOCAL_LINEAR_FROM,
    payload: [id, from],
});
export const changeLocalLinearTo = (id, to) => ({
    type: CHANGE_LOCAL_LINEAR_TO,
    payload: [id, to],
});
export const changeLocalQuestionName = (id, name) => ({
    type: CHANGE_LOCAL_QUESTION_NAME,
    payload: [id, name],
});
export const changeLocalQuestionImage = (id, image) => ({
    type: CHANGE_LOCAL_QUESTION_IMAGE,
    payload: {
        id,
        image,
    },
});
export const changeLocalQuestionQuantity = (id, name) => ({
    type: CHANGE_LOCAL_QUESTION_QUANTITY,
    payload: [id, name],
});
export const changeLocalQuestionValue = (questionInd, answerInd, value, additionalScaleId = null) => ({
    type: CHANGE_LOCAL_QUESTION_VALUE,
    payload: { questionInd, answerInd, value, additionalScaleId },
});
// Deprecated!
// export const changeLocalQuestionQuestion = (id, value) => ({
//     type: CHANGE_LOCAL_QUESTION_QUESTION,
//     payload: [id, value],
// });
export const changeLocalQuestionAnswer = (id1, id2, field, value) => ({
    type: CHANGE_LOCAL_QUESTION_ANSWER,
    payload: { id1, id2, field, value },
});
export const changeLocalScaleName = (id, name) => ({
    type: CHANGE_LOCAL_SCALE_NAME,
    payload: [id, name],
});
export const changeLocalScaleInterpretation = (scaleId, levelId, name) => ({
    type: CHANGE_LOCAL_SCALE_INTERPRETATION,
    payload: [scaleId, levelId, name],
});
export const changeLocalScaleRecommendation = (scaleId, levelId, name) => ({
    type: CHANGE_LOCAL_SCALE_RECOMMENDATION,
    payload: [scaleId, levelId, name],
});
export const changeLocalScaleDescription = (id, description) => ({
    type: CHANGE_LOCAL_SCALE_DESCRIPTION,
    payload: [id, description],
});
export const changeLocalScaleFrom = (scaleId, levelId, from) => ({
    type: CHANGE_LOCAL_SCALE_FROM,
    payload: [scaleId, levelId, from],
});
export const changeLocalScaleTo = (scaleId, levelId, to) => ({
    type: CHANGE_LOCAL_SCALE_TO,
    payload: [scaleId, levelId, to],
});
export const changeLocalScaleColor = (scaleId, levelId, color) => ({
    type: CHANGE_LOCAL_SCALE_COLOR,
    payload: [scaleId, levelId, color],
});
export const changeLocalSettingsLevel = (value) => ({
    type: CHANGE_LOCAL_SETTINGS_VALUE,
    payload: value,
});
export const changeLocalTypeScale = (id, scaleId) => ({
    type: CHANGE_LOCAL_TYPE_SCALE,
    payload: [id, scaleId],
});
export const changeLocalCountingType = (id) => ({
    type: CHANGE_LOCAL_COUNTING_TYPE,
    payload: id,
});
export const startLocalQuestionsScales = () => ({
    type: START_LOCAL_QUESTION_SCALES,
});
export const updateLocalQuestionsScales = () => ({
    type: UPDATE_LOCAL_QUESTION_SCALES,
});
export const changeLocalIsLogged = (data) => ({
    type: CHANGE_LOCAL_IS_LOGGED,
    payload: data,
});
export const changeLocalIntegral = (id, value) => ({
    type: CHANGE_LOCAL_INTEGRAL,
    payload: [id, value],
});
export const initLocalScalesErrorsArray = () => ({
    type: INIT_LOCAL_SCALES_TO_ERRORS,
});
export const changeLocalScaleErrorState = (scaleId, levelId, type, value) => ({
    type: CHANGE_LOCAL_SCALE_ERROR_STATE,
    payload: [scaleId, levelId, type, value],
});
export const changeLocalScaleErrorMessage = (scaleId, levelId, type, value) => ({
    type: CHANGE_LOCAL_SCALE_ERROR_MESSAGE,
    payload: [scaleId, levelId, type, value],
});
export const changeLocalFields = (payload) => ({
    type: CHANGE_LOCAL_FIELDS,
    payload,
});
export const showBanner = () => ({ type: SHOW_BANNER });
export const hideBanner = () => ({ type: HIDE_BANNER });
export const setIsLoadedTests = () => ({ type: SET_ISLOADED_TESTS });
export const setIsLoadedQuestions = () => ({ type: SET_ISLOADED_QUESTIONS });
export const setIsLoadedScales = () => ({ type: SET_ISLOADED_SCALES });

export const loadTests = (payload) => ({ type: LOAD_TESTS, payload });
export const loadScales = (payload) => ({ type: LOAD_SCALES, payload });
export const loadScaleSettings = (payload) => ({
    type: LOAD_SCALE_SETTINGS,
    payload,
});
export const loadQuestions = (payload) => ({ type: LOAD_QUESTIONS, payload });
export const loadStaticDirections = (payload) => ({
    type: LOAD_STATIC_DIRECTIONS,
    payload,
});
export const loadStaticTags = (payload) => ({
    type: LOAD_STATIC_TAGS,
    payload,
});
export const loadCountingTypes = (payload) => ({
    type: LOAD_COUNTING_TYPES,
    payload,
});
export const setCurrentTest = (data) => ({
    type: SET_CURRENT_TEST,
    payload: data,
});
export const setCurrentTestById = (id) => ({
    type: SET_CURRENT_TEST_BY_ID,
    payload: id,
});

let timeout = null;
export const fetchTests = () => {
    return function (dispatch) {
        fetch('/api/test', {
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.message !== 'Нет авторизации') {
                    dispatch(loadTests(data));
                    dispatch(changeLocalIsLogged(true));
                }
            })
            .then(() => {
                setIsLoadedTests();
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const deleteTest = (id) => {
    return function (dispatch) {
        fetch('/api/test/' + id + '/delete', {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then(function (data) {
                dispatch(fetchTests());
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const createTest = () => {
    return function (dispatch) {
        fetch('/api/test/', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(setCurrentTest(data.test));
                dispatch(fetchQuestions(data.test._id));
                dispatch(fetchScales(data.test._id));
                dispatch(fetchScaleSettings(data.test._id));
                dispatch(fetchTests());
                return data;
            })
            .then((data) => {
                window.location.href = '/test/' + data.test._id;
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const copyTest = (id) => {
    return function (dispatch) {
        fetch('/api/test/' + id, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then(function (data) {
                dispatch(fetchTests());
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const updateTestField = (id, fields) => {
    return function (dispatch) {
        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', 'Bearer ' + getCookie('token'));
        fetch('/api/test/' + id + '/restore', {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify(fields),
        })
            .then(() => {
                dispatch(fetchTests());
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const fetchScales = (id) => {
    return function (dispatch) {
        fetch('/api/scales/' + id, {
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(loadScales(data));
                dispatch(setIsLoadedScales());
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const createScale = (id) => {
    return function (dispatch) {
        fetch('/api/scales/' + id, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then(() => {
                dispatch(fetchScales(id));
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const createIntegral = (id) => {
    return function (dispatch) {
        fetch('/api/scales/' + id, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                let fields = {
                    integral: true,
                    name: 'Интегральная',
                };
                dispatch(updateScale(data.scales._id, id, fields));
            })
            .then(() => {
                dispatch(fetchScales(id));
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const copyScale = (scaleId, testId, scale) => {
    return function (dispatch) {
        fetch('/api/scales/' + testId, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then((data) => {
                data.json().then((newData) => {
                    let fields = {
                        testId: testId,
                        levels: scale.levels,
                        integral: scale.integral,
                        name: scale.name,
                        description: scale.description,
                    };
                    dispatch(updateScale(newData.scales._id, testId, fields));
                });
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }

                //
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const updateScale = (scaleId, testId, fields) => {
    return function (dispatch) {
        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', 'Bearer ' + getCookie('token'));
        fetch('/api/scales/' + scaleId, {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify(fields),
        })
            .then(() => {
                dispatch(fetchScales(testId));
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const deleteScale = (testId, scaleId) => {
    return function (dispatch) {
        fetch('/api/scales/' + scaleId, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then(() => {
                dispatch(fetchScales(testId));
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};

export const fetchQuestions = (id) => {
    return function (dispatch) {
        fetch('/api/question/' + id, {
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(loadQuestions(data));
                setIsLoadedQuestions();
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const createQuestion = (id) => {
    return function (dispatch) {
        fetch('/api/question/' + id, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then(() => {
                dispatch(fetchQuestions(id));
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const deleteQuestion = (testId, questionId) => {
    return function (dispatch) {
        fetch('/api/question/' + questionId, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then(() => {
                dispatch(fetchQuestions(testId));
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const updateQuestion = (questionId, testId, fields) => {
    return function (dispatch) {
        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', 'Bearer ' + getCookie('token'));
        fetch('/api/question/' + questionId, {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify(fields),
        })
            .then(() => {
                dispatch(fetchQuestions(testId));
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const copyQuestion = (id, question) => {
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + getCookie('token'));
    return function (dispatch) {
        fetch('/api/question/' + id, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                headers: question.headers,
                integral: question.integral,
                range: question.range,
                typeScale: question.typeScale,
                selection: question.selection,
                testId: question.testId,
                questionType: question.questionType,
                answerQuantity: question.answerQuantity,
            }),
        })
            .then(() => {
                dispatch(fetchQuestions(id));
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};

export const fetchStaticDirections = () => {
    return function (dispatch) {
        fetch('/api/test/directions')
            .then((response) => response.json())
            .then((data) => {
                dispatch(loadStaticDirections(data));
            })
            .catch((error) => {
                dispatch(loadStaticDirections([]));
                console.log('Request failed', error);
            });
    };
};

export const fetchStaticTags = () => {
    return function (dispatch) {
        fetch('/api/tags', {
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(loadStaticTags(data));
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};

export const createStaticTag = (id, name, tags) => {
    return function (dispatch) {
        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', 'Bearer ' + getCookie('token'));
        fetch('/api/tags/', {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                name: name,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                let tagsIds = [];
                for (let i = 0; i < tags.length; i++) {
                    if (tags[i]._id) {
                        tagsIds.push(tags[i]._id);
                    }
                }
                let newTags = tags;
                newTags.push(data);
                tagsIds.push(data._id);
                let field = {
                    tags: tagsIds,
                };
                dispatch(updateTestField(id, field));
                dispatch(fetchStaticTags());
                dispatch(changeLocalTags(newTags));
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};

export const fetchScaleSettings = (testId) => {
    return function (dispatch) {
        fetch('/api/settings-scale/' + testId, {
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(loadScaleSettings(data));
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const fetchCountingTypes = () => {
    return function (dispatch) {
        fetch('/api/counting', {
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(loadCountingTypes(data));
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
export const updateScaleSettings = (settingsId, testId, fields) => {
    return function (dispatch) {
        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', 'Bearer ' + getCookie('token'));
        fetch('/api/settings-scale/' + settingsId, {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify(fields),
        })
            .then(() => {
                //dispatch(fetchScaleSettings(testId))
                dispatch(fetchScales(testId));
                if (timeout === null) {
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                } else {
                    clearTimeout(timeout);
                    dispatch(showBanner());
                    timeout = setTimeout(() => {
                        dispatch(hideBanner());
                        timeout = null;
                    }, 2000);
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
// export const fetchLogin = (email, password) => {
//     return function (dispatch) {
//         fetch('/api/users/login', {
//             method: 'POST',
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + getCookie('token'),
//             },
//             credentials: 'same-origin',
//             body: JSON.stringify({
//                 email: 'dev.daminik00@gmail.com',
//                 password: 'Luabeo1!',
//             }),
//         })
//             .then((data) => {
//                 data.json().then((data2) => {
//                     if (
//                         data2.message ===
//                             'Некорректные данные при входе в систему' ||
//                         data2.message === 'Пользователь не найден'
//                     ) {
//                     }
//                     if (data2.token) {
//                         setCookie('token', data2.token, 1000);
//                         dispatch(fetchTests());
//                         dispatch(changeLocalIsLogged(true));
//                     }
//                 });
//             })
//             .catch(function (error) {
//                 console.log('Request failed', error);
//             });
//     };
// };
// function setCookie(name, value, days) {
//     var expires = '';
//     if (days) {
//         var date = new Date();
//         date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//         expires = '; expires=' + date.toUTCString();
//     }
//     document.cookie = name + '=' + (value || '') + expires + '; path=/';
// }
// function getCookie(name) {
//     var nameEQ = name + '=';
//     var ca = document.cookie.split(';');
//     for (var i = 0; i < ca.length; i++) {
//         var c = ca[i];
//         while (c.charAt(0) === ' ') c = c.substring(1, c.length);
//         if (c.indexOf(nameEQ) === 0)
//             return c.substring(nameEQ.length, c.length);
//     }
//     return null;
// }
// function eraseCookie(name) {
//     document.cookie =
//         name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
// }
