import React from 'react';
import { useDispatch } from 'react-redux';

import { changeLocalQuestionAnswer, updateQuestion } from '../../../Redux/reducers/mainReducer';
import infoDot from '../img/infoError.svg';
import roundClose from '../img/roundClose.svg';

export default function QuestionAnswerText({ value, label, itemSelection, questionId, currentTestId, id1, id2 }) {
    const dispatch = useDispatch();

    const handleChangeBlur = (e) => {
        let selection = [...itemSelection];
        selection[id2].question = e.target.value;

        dispatch(
            updateQuestion(questionId, currentTestId, {
                selection,
            })
        );
    };

    const handleChangeValue = (e) => {
        dispatch(changeLocalQuestionAnswer(id1, id2, 'question', e.target.value));
    };

    const removeQuestion = () => {
        const selection = [...itemSelection];
        selection.splice(id2, 1);

        dispatch(
            updateQuestion(questionId, currentTestId, {
                selection,
            })
        );
    };

    return (
        <div
            onClick={(e) => {
                e.currentTarget.firstElementChild.focus();
            }}
            className="dropdownInput"
        >
            <input onBlur={handleChangeBlur} value={value} onChange={handleChangeValue} placeholder=" " type="text" />
            <label>{label}</label>
            <img className="infoDot" src={infoDot} alt="" />
            <div
                onClick={() => {
                    removeQuestion();
                }}
                className="roundClose"
            >
                <img src={roundClose} alt="" />
            </div>
            <div className="infoBlock"></div>
        </div>
    );
}
