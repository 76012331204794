import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { changeLocalSettingsLevel, changeLocalCountingType, updateScaleSettings } from 'Redux/reducers/mainReducer';
import { showAlert } from 'Redux/actions/alertActions';
import { MenuQuestionsAndScalesNavigator } from '../MenuQuestionsAndScalesNavigator';
import { getQuestionOrScaleIndex, searchScales } from '../QuestionTestCreation/utils';
import { checkTestFilling } from 'helpers/checkTestFilling';
import { useTestFilling } from 'store/useTestFilling';
import infoError from 'pages/TestCreation/img/infoError.svg';
import arrowDown from '../../img/arrowDown.svg';
import search from '../../img/search.svg';
import { ReactComponent as QuestionIcon } from 'assets/img/question.svg';

const scalesLevels = Array.from({ length: 5 }, (_, i) => i + 1);

export const ScalesTestCreationLeftMenu = ({ currentScaleIndex, changeCurrentScaleIndex }) => {
    const dispatch = useDispatch();
    const currentTest = useSelector((state) => state.main.currentTest);
    const countingTypes = useSelector((state) => state.main.countingTypes);
    const [filteredScales, setFilteredScales] = useState([]);
    const { state: testFilling, updateTestFilling } = useTestFilling();

    useEffect(() => {
        if (currentTest?.scales) {
            setFilteredScales(currentTest.scales);
        }
    }, [currentTest.scales]);

    function setLevelSettings(value) {
        dispatch(changeLocalSettingsLevel(value));
        dispatch(
            updateScaleSettings(currentTest.settings[0]._id, currentTest._id, {
                level: value,
            })
        );
    }

    function returnCurrentCountingType() {
        let currentTypeID;
        if (currentTest.settings) {
            if (currentTest.settings[0]) {
                currentTypeID = currentTest.settings[0].countingMethodId;
            }
        }

        let name = 'Метод подсчета';

        countingTypes.forEach((type, typeId) => {
            if (type._id === currentTypeID) {
                name = type.name;
            }
        });

        return name;
    }

    function changeCountingType(id) {
        dispatch(
            updateScaleSettings(currentTest.settings[0]._id, currentTest._id, {
                countingMethodId: id,
            })
        );
        dispatch(changeLocalCountingType(id));
    }

    const handleChangeCurrentScaleIndex = (id) => {
        const finedIndex = getQuestionOrScaleIndex(currentTest.scales, id);
        if (finedIndex !== null) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            changeCurrentScaleIndex(finedIndex);
        } else {
            dispatch(showAlert({ type: 'error', text: 'Шкала не найдена' }));
        }
    };

    const handleChangeSearch = (value) => {
        setFilteredScales(searchScales(value, currentTest.scales));
    };

    const handleCheckTestFilling = () => {
        const checkResult = checkTestFilling(currentTest);
        updateTestFilling(checkResult);
    };

    return (
        <div className="leftMenu">
            <button onClick={handleCheckTestFilling} className="leftMenuButtonBlue" style={{ marginTop: 0 }}>
                Проверить заполненность
            </button>

            <div className="checkBlock" style={{ marginTop: 8, borderTop: 0 }}>
                <h4>Настройка шкал</h4>
                <p>
                    Настройки распространяться <br />
                    для всех шкал в этом тесте
                </p>
                <div className="levelDropdown">
                    <p>
                        <span style={{ position: 'relative' }}>
                            {countingTypes ? returnCurrentCountingType() : ''}
                            <QuestionIcon
                                className="question-icon"
                                data-tooltip-id="react-tooltip"
                                data-tooltip-content="Результат теста может выводиться исходя из среднего значения (отношение суммы баллов к количеству вопросов в тесте) или суммарного количества набранных баллов."
                                data-tooltip-variant="dark"
                                style={{ top: '-5px', right: '-22px' }}
                            />
                        </span>
                    </p>
                    <img src={arrowDown} />
                    <div className="levelDropdownContent onlyText">
                        {countingTypes
                            ? countingTypes.map((type, typeId) => {
                                  return (
                                      <p
                                          key={typeId}
                                          onClick={() => {
                                              changeCountingType(type._id);
                                          }}
                                      >
                                          {type.name}
                                      </p>
                                  );
                              })
                            : ''}
                    </div>
                </div>
                <div className="levelDropdown">
                    <p>
                        {/* {currentTest.settings
                            ? currentTest.settings[0]
                                ? currentTest.settings[0].level !== null
                                    ? currentTest.settings[0].level === 1
                                        ? currentTest.settings[0].level + ' уровень'
                                        : currentTest.settings[0].level === 5
                                        ? currentTest.settings[0].level + ' уровней'
                                        : currentTest.settings[0].level + ' уровня'
                                    : 'Выберите уровень'
                                : 'Выберите уровень'
                            : 'Выберите уровень'} */}

                        <span style={{ position: 'relative' }}>
                            {currentTest.settings
                                ? currentTest.settings[0]
                                    ? currentTest.settings[0].level !== null
                                        ? currentTest.settings[0].level === 1
                                            ? currentTest.settings[0].level + ' уровень'
                                            : currentTest.settings[0].level === 5
                                            ? currentTest.settings[0].level + ' уровней'
                                            : currentTest.settings[0].level + ' уровня'
                                        : 'Выберите уровень'
                                    : 'Выберите уровень'
                                : 'Выберите уровень'}
                            <QuestionIcon
                                className="question-icon"
                                data-tooltip-id="react-tooltip"
                                data-tooltip-content="Количество уровней равно числу исходов по результатам тестирования. Каждый уровень отражает степень выраженности или интенсивности измеряемого психологического качества или свойства."
                                data-tooltip-variant="dark"
                                style={{ top: '-5px', right: '-22px' }}
                            />
                        </span>
                    </p>
                    <img src={arrowDown} />
                    <div className="levelDropdownContent onlyText">
                        {scalesLevels.map((level) => (
                            <p key={level} onClick={() => setLevelSettings(level)}>
                                {level} уровень
                            </p>
                        ))}
                    </div>
                </div>
            </div>

            {!!currentTest?.scales?.length && (
                <div className="checkBlock">
                    <MenuQuestionsAndScalesNavigator
                        currentIndex={currentScaleIndex}
                        changeCurrentIndex={changeCurrentScaleIndex}
                        listLength={currentTest?.scales?.length}
                    />
                    <div className="search" style={{ marginTop: 12 }}>
                        <img src={search} alt="" />
                        <input onChange={(e) => handleChangeSearch(e.target.value)} type="text" placeholder="Поиск" />
                    </div>
                </div>
            )}

            <div style={{ padding: '0 12px', backgroundColor: 'white', borderTop: '1px solid #d3dade' }}>
                {filteredScales.length ? (
                    filteredScales.map((item, id1) => {
                        const scaleNumber = getQuestionOrScaleIndex(currentTest.scales, item._id);

                        return (
                            <div
                                key={id1}
                                onClick={() => handleChangeCurrentScaleIndex(item._id)}
                                className={cn('questionShort scaleListItem', {
                                    active: scaleNumber === currentScaleIndex,
                                })}
                                style={{ cursor: 'pointer', height: 'auto' }}
                            >
                                <label>{`Шкала ${scaleNumber !== null ? scaleNumber + 1 : ''}`}</label>
                                <p style={{ marginBottom: 0 }}>{item.name}</p>

                                {testFilling.scales.includes(item._id) && (
                                    <img
                                        className="infoDot"
                                        src={infoError}
                                        alt=""
                                        data-tooltip-id="react-tooltip"
                                        data-tooltip-content="Есть незаполненные поля"
                                        data-tooltip-variant="error"
                                    />
                                )}
                            </div>
                        );
                    })
                ) : (
                    <p style={{ padding: '24px 0', textAlign: 'center' }}>Шкалы не найдены</p>
                )}
            </div>
        </div>
    );
};
