import React from "react";

import { TESTU_LINK } from '../../../config'
// import profile from '../img/profile.jpg'
// import arrowDown from '../img/arrowDown.svg'

export default function ProfileHeaderButton(){
    return(
    // <div className="profileHeaderButton">
    //     <button>
    //         <div className="circleHolder">
    //             <img alt="" src={profile}/>
    //         </div>
    //         <img alt="" src={arrowDown}/>
    //     </button>
    // </div>
        <a href={`${TESTU_LINK}/dashboard`} className="profileButton">Профиль</a>
    )
}