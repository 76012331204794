import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { changeLocalAges, updateTestField } from 'Redux/reducers/mainReducer';
import { selectUpdateLocaleChanges, useTempLocalChanges } from 'store/useTempLocalChanges';
import infoDot from '../img/infoError.svg';

const MIN_AGE = 1;
const MAX_AGE = 99;

export function DropdownAgeInput({ currentTest }) {
    const dispatch = useDispatch();
    const updateLocalChanges = useTempLocalChanges(selectUpdateLocaleChanges);
    const [ageFrom, setAgeFrom] = useState('');
    const [ageTo, setAgeTo] = useState('');
    const [agesError, setAgesError] = useState(false);

    useEffect(() => {
        if (currentTest.ages) {
            const { min, max } = currentTest.ages;

            setAgeFrom(min);
            setAgeTo(max);
        }
    }, [currentTest]);

    useEffect(() => {
        if (ageFrom && ageTo) {
            if (Number(ageFrom) > Number(ageTo)) {
                setAgesError(true);
            } else {
                setAgesError(false);
            }
        } else {
            setAgesError(false);
        }
    }, [ageFrom, ageTo]);

    const changeAgeHandler = (e, type) => {
        const { value } = e.target;

        if (value.length > 0) {
            if (!/^\d+$/.test(value) || Number(value) < MIN_AGE || Number(value) > MAX_AGE) {
                return;
            }
        }

        if (type === 'ageFrom') {
            setAgeFrom(value);
        } else if (type === 'ageTo') {
            setAgeTo(value);
        }
    };

    const blurAgeHandler = () => {
        if (ageTo && ageFrom && !agesError) {
            const data = {
                min: ageFrom,
                max: ageTo,
            };

            const fields = {
                ages: data,
            };

            dispatch(changeLocalAges(data));

            if (currentTest.autosave) {
                dispatch(updateTestField(currentTest._id, fields));
            } else {
                updateLocalChanges(fields);
            }
        }
    };

    return (
        <>
            <div
                onClick={(e) => {
                    e.currentTarget.firstElementChild.focus();
                }}
                className={`dropdownInput value ${agesError ? 'error' : ''}`}
            >
                <input
                    value={ageFrom}
                    onBlur={blurAgeHandler}
                    onChange={(e) => changeAgeHandler(e, 'ageFrom')}
                    type="text"
                />
                <label>От</label>
                {agesError && (
                    <>
                        <img className="infoDot" src={infoDot} alt="" />
                        <div className="infoBlock">Возраст ОТ должен быть меньше ДО</div>
                    </>
                )}
            </div>

            <div
                onClick={(e) => {
                    e.currentTarget.firstElementChild.focus();
                }}
                className={`dropdownInput value ${agesError ? 'error' : ''}`}
            >
                <input
                    value={ageTo}
                    onBlur={blurAgeHandler}
                    onChange={(e) => changeAgeHandler(e, 'ageTo')}
                    type="text"
                />
                <label>До</label>
                {agesError && (
                    <>
                        <img className="infoDot" src={infoDot} alt="" />
                        <div className="infoBlock">Возраст ДО должен быть больше ОТ</div>
                    </>
                )}
            </div>
        </>
    );
}
