import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ScalesTestCreationLeftMenu } from './ScalesTestCreation/ScalesTestCreationLeftMenu';
import { ScalesTestCreationRightMenu } from './ScalesTestCreation/ScalesTestCreationRightMenu';

export function ScalesTestCreation() {
    const { testId } = useParams();
    const [currentScaleIndex, setCurrentScaleIndex] = useState(0);

    useEffect(() => {
        setCurrentScaleIndex(0);
    }, [testId]);

    return (
        <div className="row scales">
            <ScalesTestCreationLeftMenu
                currentScaleIndex={currentScaleIndex}
                changeCurrentScaleIndex={setCurrentScaleIndex}
            />
            <ScalesTestCreationRightMenu
                currentScaleIndex={currentScaleIndex}
                changeCurrentScaleIndex={setCurrentScaleIndex}
            />
        </div>
    );
}
