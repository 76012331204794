import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { startLocalQuestionsScales } from 'Redux/reducers/mainReducer';
import { showAlert } from 'Redux/actions/alertActions';
import { MenuQuestionsAndScalesNavigator } from '../MenuQuestionsAndScalesNavigator';
import { getColors, getQuestionOrScaleIndex, searchQuestions } from './utils';
import search from '../../img/search.svg';

export function QuestionTestCreationLeftMenu({ currentQuestionIndex, changeCurrentQuestionIndex }) {
    const dispatch = useDispatch();
    const currentTest = useSelector((state) => state.main.currentTest);
    const [searchQuestionsArray, setSearchQuestionsArray] = useState([]);

    useEffect(() => {
        if (currentTest?.questions) {
            setSearchQuestionsArray(currentTest.questions);
        }
    }, [currentTest.questions]);

    useEffect(() => {
        if (currentTest.scales) {
            dispatch(startLocalQuestionsScales());
        }
    }, [dispatch, currentTest.scales]);

    const handleChangeSearch = (value) => {
        setSearchQuestionsArray(searchQuestions(value, currentTest.questions));
    };

    const handleChangeCurrentQuestionIndex = (id) => {
        const finedIndex = getQuestionOrScaleIndex(currentTest.questions, id);

        if (finedIndex !== null) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            changeCurrentQuestionIndex(finedIndex);
        } else {
            dispatch(showAlert({ type: 'error', text: 'Вопрос не найден' }));
        }
    };

    return (
        <div className="leftMenu">
            <MenuQuestionsAndScalesNavigator
                currentIndex={currentQuestionIndex}
                changeCurrentIndex={changeCurrentQuestionIndex}
                listLength={currentTest?.questions?.length}
            />

            <div className="search" style={{ marginTop: 12 }}>
                <img src={search} alt="" />
                <input onChange={(e) => handleChangeSearch(e.target.value)} type="text" placeholder="Поиск" />
            </div>

            {searchQuestionsArray.map((item, id1) => {
                const questionNumber = getQuestionOrScaleIndex(currentTest.questions, item._id);

                return (
                    <div
                        key={id1}
                        onClick={() => handleChangeCurrentQuestionIndex(item._id)}
                        className={questionNumber === currentQuestionIndex ? 'questionShort active' : 'questionShort'}
                        style={{ cursor: 'pointer' }}
                    >
                        <label>{`Вопрос ${questionNumber !== null ? questionNumber + 1 : ''}`}</label>
                        <p>{item.headers.length > 25 ? item.headers.slice(0, 24) + '..' : item.headers}</p>
                        <div className="row">
                            <div className="chips">
                                {item.questionType === 'Text'
                                    ? 'Свободная форма'
                                    : item.selection
                                    ? 'Выбор варианта'
                                    : 'Ползунок'}
                            </div>
                            <div className="colors">
                                {getColors(id1, currentTest.questionsScales).map((item, itemOrderId) => {
                                    if (item === null) return null;

                                    return (
                                        <div key={itemOrderId} style={{ backgroundColor: item }} className="colorDot" />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
