import React, { useEffect } from 'react';
import TestsBlock from './TestsBlock';
import addTestImg from '../img/addTest.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createTest } from '../../../Redux/reducers/mainReducer';
import arrowDown from '../../TestCreation/img/arrowDown.svg';
import zoomImg from '../img/zoomImg.svg';
import hashTagImg from '../img/hashTagImg.svg';
import crossImg from '../img/deleteIcon.svg';

export default function TestsContent() {
    let dispatch = useDispatch();

    let tests = useSelector((state) => state.main.tests);
    let tags = useSelector((state) => state.main.staticTags);
    // let isTestsLoaded = useSelector(state => state.main.isTestsLoaded)
    let [currentHashtag, setCurrentHashtag] = React.useState();
    let [currentSearch, setCurrentSearch] = React.useState('');
    function inputHandler(e) {
        setCurrentSearch(e.target.value);
    }
    // function returnUsedTags(){
    //     let usedTags = [];
    //     tests.map((test, testOrderId) => {
    //         let testUsedTags = [];
    //         test.tags.map((tag, tagOrderId) => {
    //             if(!usedTags.includes(tag)){
    //                 testUsedTags.push(tag)
    //             }
    //         })
    //         if(testUsedTags.length > 0){
    //             usedTags.push(testUsedTags)
    //         }
    //     })
    //     return usedTags
    // }

    let testsPublished = [];
    let testsNotPublished = [];

    if (tests) {
        if (currentHashtag && !currentSearch) {
            for (let i = 0; i < tests.length; i++) {
                if (tests[i].tags.length > 0) {
                    tests[i].tags.map((tag, tagOrderId) => {
                        if (currentHashtag._id === tag._id) {
                            if (tests[i].public) {
                                testsPublished.push(tests[i]);
                            } else {
                                testsNotPublished.push(tests[i]);
                            }
                        }
                    });
                }
            }
        } else if (currentHashtag && currentSearch) {
            for (let i = 0; i < tests.length; i++) {
                if (tests[i].tags.length > 0) {
                    tests[i].tags.map((tag, tagOrderId) => {
                        if (currentHashtag._id === tag._id) {
                            let toCompare = tests[i].name.slice(0, currentSearch.length);
                            if (toCompare.toLowerCase() === currentSearch.toLowerCase()) {
                                if (tests[i].public) {
                                    testsPublished.push(tests[i]);
                                } else {
                                    testsNotPublished.push(tests[i]);
                                }
                            }
                        }
                    });
                }
            }
        } else if (!currentHashtag && currentSearch) {
            for (let i = 0; i < tests.length; i++) {
                let toCompare = tests[i].name.slice(0, currentSearch.length);
                if (toCompare.toLowerCase() === currentSearch.toLowerCase()) {
                    if (tests[i].public) {
                        testsPublished.push(tests[i]);
                    } else {
                        testsNotPublished.push(tests[i]);
                    }
                }
            }
        } else {
            for (let i = 0; i < tests.length; i++) {
                if (tests[i].public) {
                    testsPublished.push(tests[i]);
                } else {
                    testsNotPublished.push(tests[i]);
                }
            }
        }
    }

    const [isPublished, setIsPublished] = React.useState(false);
    let location = useLocation();
    let navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === '/tests/published') {
            setIsPublished(true);
        } else {
            setIsPublished(false);
        }
    }, []);

    return (
        <div className="testsHolder">
            <div className="testsContent container">
                <div className="row">
                    <h1>Мои тесты</h1>
                    <Link to="/test">
                        <button
                            onClick={() => {
                                dispatch(createTest());
                            }}
                            className="purpleFilled"
                        >
                            Создать тест
                        </button>
                    </Link>
                </div>
                <div className="isPublishedButtons row">
                    <button
                        onClick={() => {
                            setIsPublished(false);
                            navigate('/tests');
                        }}
                        className={isPublished ? '' : 'active'}
                    >
                        Не опубликованные
                        <div className="counter">{testsNotPublished ? testsNotPublished.length : ''}</div>
                    </button>
                    <button
                        onClick={() => {
                            setIsPublished(true);
                            navigate('/tests/published');
                        }}
                        className={isPublished ? 'active' : ''}
                    >
                        Опубликованные
                        <div className="counter">{testsPublished ? testsPublished.length : ''}</div>
                    </button>
                </div>
            </div>
            <div className="testsSearch">
                <div className="container">
                    <div className="searchHolder">
                        <img src={zoomImg} alt="" />
                        <input
                            value={currentSearch}
                            onChange={(e) => {
                                inputHandler(e);
                            }}
                            type="text"
                            placeholder="Поиск"
                        />
                        <button>ПОИСК</button>
                    </div>
                    <div className="hashtagsSearch">
                        {currentHashtag ? (
                            <div className="tagChosen">
                                <img src={hashTagImg} alt="" />
                                <p>{currentHashtag.name}</p>
                                <img
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setCurrentHashtag();
                                    }}
                                    src={crossImg}
                                    alt=""
                                />
                            </div>
                        ) : (
                            <div className="levelDropdown">
                                <img src={hashTagImg} alt="" />
                                <p>Хештеги</p>
                                <img src={arrowDown} />
                                <div className="levelDropdownContent onlyText chipDrop">
                                    {tags
                                        ? tags.map((tag, tagOrderId) => {
                                              return (
                                                  <div
                                                      key={tagOrderId}
                                                      onClick={() => {
                                                          setCurrentHashtag(tag);
                                                      }}
                                                      className="chip"
                                                  >
                                                      {tag.name}
                                                  </div>
                                              );
                                          })
                                        : ''}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {isPublished ? (
                testsPublished.length > 0 ? (
                    <TestsBlock tests={testsPublished} />
                ) : (
                    <Link to="/test">
                        <div
                            onClick={() => {
                                dispatch(createTest());
                            }}
                            className="cardHolder no-tests"
                        >
                            <div className="container">
                                <div className="addTest">
                                    <div className="row">
                                        <div className="plusButton">
                                            <img src={addTestImg} />
                                        </div>
                                        <p>Создать тест</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                )
            ) : testsNotPublished.length > 0 ? (
                <TestsBlock tests={testsNotPublished} />
            ) : (
                <Link to="/test">
                    <div
                        onClick={() => {
                            dispatch(createTest());
                        }}
                        className="cardHolder no-tests"
                    >
                        <div className="container">
                            <div className="addTest">
                                <div className="row">
                                    <div className="plusButton">
                                        <img src={addTestImg} />
                                    </div>
                                    <p>Создать тест</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            )}
        </div>
    );
}
