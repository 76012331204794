import React from 'react';
import {useDispatch} from 'react-redux'

import {hideAlert} from '../../Redux/actions/alertActions'
import './alert.css';

export const Alert = ({ type, text }) => {
  const dispatch = useDispatch()

  const closePopup = () => {
    dispatch(hideAlert())
  }

  return (
    <div
      className="alert-wrapper"
      onClick={closePopup}
    >
      <div
        className={`alert ${!!type && 'alert_' + type}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {!!type && <span />}
        <p>{text}</p>
      </div>
    </div>
  );
};
