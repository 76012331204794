import React from 'react';
import cn from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { changeLocalScaleColor, updateScale } from 'Redux/reducers/mainReducer';
import DropdownInput from './DropdownInput';
import { scaleLevelsColors } from './ScalesTestCreation/consts';
import { ScaleTextField } from './ScalesTestCreation/ScaleTextField';
import arrowDown from '../img/arrowDown.svg';
import coloredCircle from '../img/coloredCircle.png';

export default function TestScale(props) {
    let dispatch = useDispatch();
    let currentTest = useSelector((state) => state.main.currentTest);

    document.addEventListener('click', (e) => {
        if (e.path) {
            let checker = 0;

            for (let i = 0; i < e.path.length; i++) {
                if (e.path[i].classList) {
                    if (!e.path[i].classList.contains('colorDropdown') && !e.path[i].classList.contains('color')) {
                        checker++;
                    }
                } else {
                    checker++;
                }
            }
            if (e.path.length === checker) {
                checker = 0;
                let toClose = document.getElementsByClassName('colorDropdown');

                for (let i = 0; i < toClose.length; i++) {
                    if (toClose[i]) {
                        setTimeout(() => {
                            if (toClose[i]) {
                                toClose[i].classList.remove('opened');
                            }
                        }, 1);
                    }
                }
            }
        }
    });

    // function openColorPicker(el) {
    //     setTimeout(() => {
    //         if (!el.classList.contains('opened')) {
    //             el.classList.add('opened');
    //         }
    //     }, 50);
    // }
    // function closeColorPicker(el) {
    //     setTimeout(() => {
    //         if (el.classList.contains('opened')) {
    //             el.classList.remove('opened');
    //         }
    //     }, 0);
    // }
    function updateColor(color) {
        const levels = [...props.currentTest.scales[props.id1].levels];
        const usedColors = levels.map((level) => level.color);
        const unusedColors = scaleLevelsColors.filter((itemColor) => !usedColors.includes(itemColor));

        levels.forEach((level, levelOrderId) => {
            if (level.color === color) {
                dispatch(changeLocalScaleColor(props.id1, levelOrderId, unusedColors[0] || levels[props.id2].color));
            }
        });

        dispatch(changeLocalScaleColor(props.id1, props.id2, color));

        levels[props.id2].color = color;

        dispatch(
            updateScale(props.scaleId, props.currentTest._id, {
                levels,
            })
        );
    }
    return (
        <div className="scale">
            <div className="scaleContent">
                <div className="row">
                    <div
                        className={cn('levelDropdown', {
                            error: !props.color,
                        })}
                    >
                        <div className="color">
                            {props.color ? (
                                <div style={{ backgroundColor: props.color }} className="color"></div>
                            ) : (
                                <img src={coloredCircle} />
                            )}
                        </div>
                        <p>Уровень {props.id2 + 1}</p>
                        <img src={arrowDown} />
                        <div className="levelDropdownContent">
                            <p>Выберите цвет уровня</p>
                            <div className="row">
                                <div
                                    onClick={() => updateColor('#80CD65')}
                                    style={{ backgroundColor: '#80CD65' }}
                                    className="color"
                                />
                                <div
                                    onClick={() => updateColor('#FECC62')}
                                    style={{ backgroundColor: '#FECC62' }}
                                    className="color"
                                />
                                <div
                                    onClick={() => updateColor('#FF5A52')}
                                    style={{ backgroundColor: '#FF5A52' }}
                                    className="color"
                                />
                                <div
                                    onClick={() => updateColor('#47A0FF')}
                                    style={{ backgroundColor: '#47A0FF' }}
                                    className="color"
                                />
                                <div
                                    onClick={() => updateColor('#858BFF')}
                                    style={{ backgroundColor: '#858BFF' }}
                                    className="color"
                                />
                            </div>
                        </div>
                    </div>
                    <DropdownInput
                        scaleId={props.scaleId}
                        currentTest={currentTest}
                        id1={props.id1}
                        id2={props.id2}
                        type="CHANGE_SCALE_FROM"
                        value={props.from}
                        label="Диапазон баллов от"
                    />
                    <DropdownInput
                        scaleId={props.scaleId}
                        currentTest={currentTest}
                        id1={props.id1}
                        id2={props.id2}
                        type="CHANGE_SCALE_TO"
                        value={props.to}
                        label="До"
                    />
                </div>
                <ScaleTextField
                    scaleId={props.scaleId}
                    scaleLevelId={props.id2}
                    currentTestId={props.currentTest._id}
                    currentScaleIndex={props.id1}
                    value={props.interpretation}
                    label="Интерпретация уровня (не отображается у ученика)"
                    field="interpretation"
                    currentTestScales={props.currentTest.scales}
                />
                <ScaleTextField
                    scaleId={props.scaleId}
                    scaleLevelId={props.id2}
                    currentTestId={props.currentTest._id}
                    currentScaleIndex={props.id1}
                    value={props.recommendation}
                    label="Рекомендации для уровня (не отображаются у ученика)"
                    field="recommendation"
                    currentTestScales={props.currentTest.scales}
                />
            </div>
        </div>
    );
}
