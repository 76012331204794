import React from 'react';

import { GeneralTestCreationLeftMenu } from './GeneralTestCreation/GeneralTestCreationLeftMenu';
import { GeneralTestCreationRightMenu } from './GeneralTestCreation/GeneralTestCreationRightMenu';

export function GeneralTestCreation() {
    return (
        <div className="row">
            <GeneralTestCreationLeftMenu />
            <GeneralTestCreationRightMenu />
        </div>
    );
}
