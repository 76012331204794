import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { updateScale, changeLocalScaleName, updateLocalQuestionsScales } from 'Redux/reducers/mainReducer';
import infoDot from '../../img/infoError.svg';

export function ScaleTitleInput({ currentTestId, scaleId, currentScaleIndex, value, isIntegral }) {
    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsError(!value);
    }, [value]);

    const handleChangeValue = (e) => {
        if (isIntegral) return;

        dispatch(changeLocalScaleName(currentScaleIndex, e.target.value));
        dispatch(updateLocalQuestionsScales());
    };

    const handleChangeBlur = (e) => {
        if (isIntegral) return;

        const { value } = e.target;

        if (value.trim() === '') {
            setIsError(true);
            return;
        }

        dispatch(
            updateScale(scaleId, currentTestId, {
                name: value,
            })
        );
    };

    return (
        <div
            onClick={(e) => e.currentTarget.firstElementChild.focus()}
            className={cn('dropdownInput value', { error: isError })}
        >
            <input
                onBlur={handleChangeBlur}
                value={value}
                onChange={handleChangeValue}
                placeholder=" "
                type="text"
                readOnly={isIntegral}
            />
            <label>Название шкалы</label>
            {isError && (
                <img
                    className="infoDot"
                    style={{ right: -30 }}
                    src={infoDot}
                    alt=""
                    data-tooltip-id="react-tooltip"
                    data-tooltip-content="Поле обязательно для заполнения"
                    data-tooltip-variant="error"
                />
            )}
        </div>
    );
}
