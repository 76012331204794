import React from 'react';

import QuestionItemScales from './QuestionItemScales';
import DropdownInput from './DropdownInput';
import QuestionItemRange from './QuestionItemRange';
import QuestionAnswerText from './QuestionAnswerText';
import QuestionAnswerValue from './QuestionAnswerValue';
import arrowDown from '../img/arrowDown.svg';
import lineType from '../img/lineType.svg';
import oneAnwer from '../img/oneAnswer.svg';
import multiAnswerImg from '../img/multiAnswer.svg';
import addButton from '../img/addTest.svg';
import uploadButton from '../img/uploadImage.svg';
import showImage from '../img/eye.svg';
import copyButton from '../img/copyButton.svg';
import deleteButton from '../img/deleteButton.svg';
import sixDots from '../img/sixDots.svg';
import { ReactComponent as QuestionIcon } from 'assets/img/question.svg';

export default function QuestionItem({
    currentTest,
    item,
    id1,
    changeTypeScale,
    addAnswerToQuestion,
    copyQuestionCallback,
    deleteQuestionCallback,
    changeQuestionType,
    integralHandler,
    handleQuantityBlur,
    handleQuantityChange,
    uploadQuestionImage,
    deleteQuestionImage,
    uploadAnswerImage,
    deleteAnswerImage,
    changeAnswerType,
    addAdditionalScale,
    deleteAdditionalScale,
}) {
    if (item.questionType !== 'Text' && !item.range && !item.selection) {
        return null;
    }

    const changeAnswerTypeHandler = (e, answerId) => {
        changeAnswerType(item._id, item.selection, answerId, e.target.checked);
    };

    return (
        <div className="question_holder">
            <div className="question">
                <img src={sixDots} alt="" />
                <DropdownInput
                    id="question_name"
                    questionId={item._id}
                    currentTest={currentTest}
                    id1={id1}
                    type="CHANGE_QUESTION_NAME"
                    value={item.headers}
                    label={'Вопрос ' + (id1 + 1)}
                />
                <div className="inputs selection">
                    {item.questionType !== 'Text' ? (
                        <>
                            <QuestionItemScales
                                item={item}
                                currentTest={currentTest}
                                id1={id1}
                                changeTypeScale={changeTypeScale}
                                addAdditionalScale={addAdditionalScale}
                                deleteAdditionalScale={deleteAdditionalScale}
                            />

                            <div className="row">
                                <div className="levelDropdown questionDropdown questionChecker no-hover-white">
                                    <p style={{ width: 298, opacity: 1 }}>
                                        <span style={{ position: 'relative' }}>
                                            Ответ в свободной форме{' '}
                                            <QuestionIcon
                                                className="question-icon"
                                                data-tooltip-id="react-tooltip"
                                                data-tooltip-content="Воспользуйтесь данным переключателем, если вопрос в тесте предполагает развернутый ответ в виде текста."
                                                data-tooltip-variant="dark"
                                                style={{ top: '-5px', right: '-22px' }}
                                            />
                                        </span>
                                    </p>
                                    <div className="checker">
                                        <input
                                            onChange={() => {
                                                const value = item.questionType === 'Text' ? 'selection' : 'text';
                                                changeQuestionType(item._id, value);
                                            }}
                                            checked={item.questionType === 'Text'}
                                            type="checkbox"
                                        />
                                        <div className="line"></div>
                                        <div className="roundCheck"></div>
                                    </div>
                                </div>
                                {/* <div className="levelDropdown questionDropdown questionChecker no-hover-white">
                                    <p>Интегральный показатель</p>
                                    <div className="checker">
                                        <input
                                            onChange={() => {
                                                integralHandler(item._id, currentTest._id, item, id1);
                                            }}
                                            checked={item.integral ? 'checked' : ''}
                                            type="checkbox"
                                        />
                                        <div className="line"></div>
                                        <div className="roundCheck"></div>
                                    </div>
                                </div> */}
                                <div className="levelDropdown questionDropdown">
                                    <p className="type_text" style={{ position: 'relative' }}>
                                        {item.range && (
                                            <div className="row">
                                                <img src={lineType} alt="" />
                                                <p>Линейная шкала</p>
                                            </div>
                                        )}
                                        {item.selection && item.questionType !== 'MultiSelection' && (
                                            <div className="row">
                                                <img src={oneAnwer} alt="" />
                                                <p>Один ответ</p>
                                            </div>
                                        )}
                                        {item.questionType === 'MultiSelection' && (
                                            <div className="row">
                                                <img src={multiAnswerImg} alt="" />
                                                <p>Несколько ответов</p>
                                            </div>
                                        )}
                                        {/* <QuestionIcon
                                            className="question-icon"
                                            data-tooltip-id="react-tooltip"
                                            data-tooltip-content="Укажите, сколько предлагается вариантов ответа на данный вопрос теста."
                                            data-tooltip-variant="dark"
                                            style={{ top: '-5px', right: '-8px' }}
                                        /> */}
                                    </p>
                                    <img src={arrowDown} alt="" />
                                    <div className="levelDropdownContent answerType">
                                        <div onClick={() => changeQuestionType(item._id, 'range')} className="row">
                                            <img src={lineType} alt="" />
                                            <p>Линейная шкала</p>
                                        </div>
                                        <div onClick={() => changeQuestionType(item._id, 'selection')} className="row">
                                            <img src={oneAnwer} alt="" />
                                            <p>Один ответ</p>
                                        </div>
                                        <div
                                            onClick={() => changeQuestionType(item._id, 'multiSelection')}
                                            className="row"
                                        >
                                            <img src={multiAnswerImg} alt="" />
                                            <p>Несколько ответов</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="row">
                            <div className="levelDropdown questionDropdown questionChecker no-hover-white">
                                <p style={{ width: 298, opacity: 1 }}>
                                    <span style={{ position: 'relative' }}>
                                        Ответ в свободной форме{' '}
                                        <QuestionIcon
                                            className="question-icon"
                                            data-tooltip-id="react-tooltip"
                                            data-tooltip-content="Воспользуйтесь данным переключателем, если вопрос в тесте предполагает развернутый ответ в виде текста."
                                            data-tooltip-variant="dark"
                                            style={{ top: '-5px', right: '-22px' }}
                                        />
                                    </span>
                                </p>
                                <div className="checker">
                                    <input
                                        onChange={() => {
                                            const value = item.questionType === 'Text' ? 'selection' : 'text';
                                            changeQuestionType(item._id, value);
                                        }}
                                        checked={item.questionType === 'Text'}
                                        type="checkbox"
                                    />
                                    <div className="line"></div>
                                    <div className="roundCheck"></div>
                                </div>
                            </div>
                        </div>
                    )}

                    {item.questionType === 'MultiSelection' && (
                        <div className="answersLimiter">
                            <p>Ограничить возможное количество выбранных ответов:</p>
                            <div className="row">
                                <input
                                    onBlur={(e) => {
                                        handleQuantityBlur(e, item._id, item);
                                    }}
                                    onChange={(e) => {
                                        handleQuantityChange(e, id1);
                                    }}
                                    value={item.answerQuantity}
                                    placeholder="Число:"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="upload-image">
                {item.image ? (
                    <div className="inner">
                        <div className="image-button">
                            <img src={showImage} alt="" />
                            <a href={`/api/static/images/${item.image}`} target="_blank" rel="noreferrer">
                                Посмотреть картинку
                            </a>
                        </div>
                        <div className="tools" style={{ width: 'auto', padding: 0 }}>
                            <div onClick={() => deleteQuestionImage(item._id, item.image)} className="deleteButton">
                                <img src={deleteButton} alt="" />
                                <p>Удалить картинку</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="inner">
                        <label htmlFor={`upload-question-image-${item._id}`} className="image-button">
                            <input
                                onChange={(e) => uploadQuestionImage(e, item._id)}
                                type="file"
                                accept="image/*"
                                id={`upload-question-image-${item._id}`}
                                hidden
                            />
                            <img src={uploadButton} alt="" />
                            <span>Загрузить картинку вопроса (опционально)</span>
                        </label>
                    </div>
                )}
            </div>

            <div className="addAnswer">
                {item.range && (
                    <QuestionItemRange currentTest={currentTest} _id={item._id} range={item.range} id1={id1} />
                )}

                {item.selection && (
                    <div>
                        {item.selection.map((item2, id2) => (
                            <div key={id2} className="answer-item">
                                <div className="answer-type">
                                    <div className="answer-type-checker">
                                        Текстовый ответ
                                        <div className="checker">
                                            <input
                                                onChange={(e) => changeAnswerTypeHandler(e, id2)}
                                                checked={!!item2.isImage}
                                                type="checkbox"
                                            />
                                            <div className="line no-color"></div>
                                            <div className="roundCheck no-color"></div>
                                        </div>
                                        Картинка
                                    </div>
                                </div>
                                <div className="answer row">
                                    {item2.isImage ? (
                                        <div className="answer-image">
                                            {item2.image ? (
                                                <>
                                                    <div className="image-button">
                                                        <img src={showImage} alt="" />
                                                        <a
                                                            href={`/api/static/images/${item2.image}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Посмотреть картинку
                                                        </a>
                                                    </div>
                                                    <div className="tools" style={{ width: 'auto', padding: 0 }}>
                                                        <div
                                                            onClick={() =>
                                                                deleteAnswerImage(item._id, id1, id2, item2.image)
                                                            }
                                                            className="deleteButton"
                                                        >
                                                            <img src={deleteButton} alt="" />
                                                            <p>Удалить картинку</p>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <label className="image-button">
                                                    <input
                                                        onChange={(e) => uploadAnswerImage(e, item._id, id1, id2)}
                                                        type="file"
                                                        accept="image/*"
                                                        hidden
                                                    />
                                                    <img src={uploadButton} alt="" />
                                                    <span>Загрузить картинку ответа</span>
                                                </label>
                                            )}
                                        </div>
                                    ) : (
                                        <QuestionAnswerText
                                            value={item2.question}
                                            label={id2 + 1 + ' вариант ответа'}
                                            id1={id1}
                                            id2={id2}
                                            questionId={item._id}
                                            itemSelection={item.selection}
                                            currentTestId={currentTest._id}
                                        />
                                    )}
                                    <QuestionAnswerValue
                                        value={item2.value}
                                        id1={id1}
                                        id2={id2}
                                        questionId={item._id}
                                        itemSelection={item.selection}
                                        currentTestId={currentTest._id}
                                        label="Значение шкалы 1"
                                    />
                                </div>

                                {item2?.additionalScales?.map(({ scale, value }, i) => (
                                    <div className="answer row" style={{ paddingTop: 0 }} key={scale}>
                                        <div style={{ flex: '1 1 auto' }} />
                                        <QuestionAnswerValue
                                            value={value}
                                            id1={id1}
                                            id2={id2}
                                            questionId={item._id}
                                            itemSelection={item.selection}
                                            currentTestId={currentTest._id}
                                            label={`Значение шкалы ${i + 2}`}
                                            additionalScaleId={i}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}

                        <div className="inner">
                            <div
                                onClick={() => {
                                    addAnswerToQuestion(item._id, item);
                                }}
                                className="row"
                            >
                                <div className="plusButton">
                                    <img src={addButton} alt="" />
                                </div>
                                <p>Добавить ответ</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="tools">
                    <div
                        onClick={() => {
                            copyQuestionCallback(currentTest._id, item);
                        }}
                        className="copyButton"
                    >
                        <img src={copyButton} alt="" />
                        <p>Создать копию</p>
                    </div>
                    <div
                        onClick={() => {
                            deleteQuestionCallback(currentTest._id, item._id);
                        }}
                        className="deleteButton"
                    >
                        <img src={deleteButton} alt="" />
                        <p>Удалить</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
