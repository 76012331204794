import React from 'react';
import DropdownHeaderButtons from './DropdownHeaderButtons';
import ProfileHeaderButton from './ProfileHeaderButton';
import { useSelector } from 'react-redux';
// import {changeLocalIsLogged, fetchLogin} from "../../../Redux/reducers/mainReducer";
import { TESTU_LINK } from '../../../config';

export default function HeaderButtons() {
  // let dispatch = useDispatch()
  let isLogged = useSelector((state) => state.main.isLogged);
  function login() {
    //  let login = window.prompt("Введите логин")
    // let password = window.prompt("Введите пароль");
    // dispatch(fetchLogin(login, password))
    window.location.href = `${TESTU_LINK}/psych/login`;
  }
  return (
    <div className="headerButtons">
      <DropdownHeaderButtons />
      {isLogged ? (
        <ProfileHeaderButton />
      ) : (
        <button
          onClick={() => {
            login();
          }}
          className="login"
        >
          Войти
        </button>
      )}
    </div>
  );
}
