import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { changeLocalTestName, updateTestField } from 'Redux/reducers/mainReducer';
import { selectUpdateLocaleChanges, useTempLocalChanges } from 'store/useTempLocalChanges';
import infoDot from '../img/infoError.svg';
import { ReactComponent as QuestionIcon } from 'assets/img/question.svg';

export function TestTitleInput({ currentTestId, value, autosave }) {
    const dispatch = useDispatch();
    const updateLocalChanges = useTempLocalChanges(selectUpdateLocaleChanges);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsError(!value);
    }, [value]);

    const handleChangeValue = (e) => {
        dispatch(changeLocalTestName(e.target.value));
    };

    const handleChangeBlur = (e) => {
        const { value } = e.target;

        if (value.trim() === '') {
            setIsError(true);
            return;
        }

        const fields = {
            name: value,
        };

        if (autosave) {
            dispatch(updateTestField(currentTestId, fields));
        } else {
            updateLocalChanges(fields);
        }
    };

    return (
        <div
            onClick={(e) => e.currentTarget.firstElementChild.focus()}
            className={cn('dropdownInput value field-with-tooltip', { error: isError })}
        >
            <input
                onBlur={handleChangeBlur}
                value={value}
                onChange={handleChangeValue}
                placeholder=" "
                type="text"
                style={{ paddingTop: 10 }}
            />
            <label>
                <span>Название теста</span>
                <QuestionIcon
                    data-tooltip-id="react-tooltip"
                    data-tooltip-content="Название теста должно отражать его тему, причину использования. Для удобства можно указать возраст и пол, кому рекомендовано прохождение данного тестирования."
                    data-tooltip-variant="dark"
                />
            </label>
            {isError && (
                <img
                    className="infoDot"
                    style={{ right: -30 }}
                    src={infoDot}
                    alt=""
                    data-tooltip-id="react-tooltip"
                    data-tooltip-content="Поле обязательно для заполнения"
                    data-tooltip-variant="error"
                />
            )}
        </div>
    );
}
