import { getCookie } from '../../helpers/cookies';
import { GET_USER } from '../types';

export const fetchUser = () => {
    return function (dispatch) {
        fetch('/api/users', {
            headers: {
                Authorization: 'Bearer ' + getCookie('token'),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch({
                    type: GET_USER,
                    payload: data,
                });
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    };
};
