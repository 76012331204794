import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { copyScale, createIntegral, createScale, deleteScale } from 'Redux/reducers/mainReducer';
import { checkScaleSettings } from './utils';
import TestScale from '../TestScale';
import addButton from '../../img/addTest.svg';
import copyButton from '../../img/copyButton.svg';
import deleteButton from '../../img/deleteButton.svg';
import { ScaleTitleInput } from './ScaleTitleInput';
import { ScaleTextField } from './ScaleTextField';
import { ReactComponent as QuestionIcon } from 'assets/img/question.svg';

export const ScalesTestCreationRightMenu = ({ currentScaleIndex, changeCurrentScaleIndex }) => {
    const dispatch = useDispatch();
    const currentTest = useSelector((state) => state.main.currentTest);
    const [scaleItem, setScaleItem] = useState(null);
    // const isThereIntegral = useMemo(
    //     () => currentTest?.scales?.some(({ integral }) => integral === true),
    //     [currentTest.scales]
    // );

    useEffect(() => {
        if (currentTest?.scales?.length && currentTest.scales[currentScaleIndex]) {
            setScaleItem(currentTest.scales[currentScaleIndex]);
        } else {
            setScaleItem(null);
        }
    }, [currentScaleIndex, currentTest]);

    function openDropdown(el) {
        setTimeout(() => {
            if (el.classList.contains('opened')) {
                el.classList.remove('opened');
            } else {
                el.classList.add('opened');
            }
        }, 20);
    }

    const hanldeScaleCopy = () => {
        dispatch(copyScale(scaleItem._id, currentTest._id, scaleItem));
        changeCurrentScaleIndex(currentTest.scales.length);
    };

    const handleScaleDelete = () => {
        const prevScaleIndex = currentScaleIndex > 0 ? currentScaleIndex - 1 : 0;
        dispatch(deleteScale(currentTest._id, scaleItem._id));
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        changeCurrentScaleIndex(prevScaleIndex);
    };

    const handleCreateScale = (isIntegral) => {
        if (isIntegral) {
            dispatch(createIntegral(currentTest._id));
        } else {
            dispatch(createScale(currentTest._id));
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        changeCurrentScaleIndex(currentTest.scales.length);
    };

    return (
        <div className="rightMenu">
            {scaleItem ? (
                <div className="scalesPage" style={{ position: 'relative' }}>
                    <div className="scaleSettings">
                        <ScaleTitleInput
                            currentTestId={currentTest._id}
                            scaleId={scaleItem._id}
                            currentScaleIndex={currentScaleIndex}
                            value={scaleItem.name}
                            isIntegral={scaleItem.integral}
                        />
                        <ScaleTextField
                            scaleId={scaleItem._id}
                            currentTestId={currentTest._id}
                            currentScaleIndex={currentScaleIndex}
                            value={scaleItem.description}
                            label="Описание шкалы"
                            field="description"
                        />
                    </div>
                    <div className="scales">
                        {scaleItem.levels
                            ? scaleItem.levels.map((level, id2) => {
                                  return (
                                      <TestScale
                                          key={id2}
                                          currentTest={currentTest}
                                          scaleId={scaleItem._id}
                                          color={level.color}
                                          id1={currentScaleIndex}
                                          id2={id2}
                                          recommendation={level.recommendation}
                                          interpretation={level.interpretation}
                                          from={level.range.from}
                                          to={level.range.to}
                                      />
                                  );
                              })
                            : ''}
                    </div>
                    <div className="tools">
                        {!scaleItem.integral ? (
                            <div onClick={hanldeScaleCopy} className="copyButton">
                                <img src={copyButton} />
                                <p>Создать копию</p>
                            </div>
                        ) : (
                            ''
                        )}
                        <div onClick={handleScaleDelete} className="deleteButton">
                            <img src={deleteButton} />
                            <p>Удалить</p>
                        </div>
                    </div>
                    <div
                        onClick={(el) => {
                            openDropdown(el.currentTarget.lastElementChild);
                        }}
                        className="addNewScaleButton"
                        style={{ bottom: '-80px' }}
                    >
                        <img src={addButton} />
                        <div className="addNewScaleButtonDropdown" style={{ top: '-110px' }}>
                            <div onClick={() => handleCreateScale(false)} className="button">
                                <div className="text">
                                    <p>
                                        <span style={{ position: 'relative' }}>
                                            Добавить шкалу{' '}
                                            <QuestionIcon
                                                className="question-icon"
                                                data-tooltip-id="react-tooltip"
                                                data-tooltip-content="Создайте шкалу, сопоставив количество набранных баллов с уровнем, то есть степенью выраженности или интенсивности измеряемого психологического качества или свойства."
                                                data-tooltip-variant="dark"
                                                style={{ top: '-5px', right: '-22px' }}
                                            />
                                        </span>
                                    </p>
                                    <label>Небольшое описание шкалы, в одном предложении</label>
                                </div>
                                <div className="plus">
                                    <img src={addButton} />
                                </div>
                            </div>
                            {/* {!isThereIntegral && (
                                <div onClick={() => handleCreateScale(true)} className="button">
                                    <div className="text">
                                        <p>Добавить интегральную шкалу</p>
                                        <label>Небольшое описание шкалы, в одном предложении</label>
                                    </div>
                                    <div className="plus">
                                        <img src={addButton} />
                                    </div>
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {checkScaleSettings(currentTest) ? (
                        <>
                            <div onClick={() => handleCreateScale(false)} className="addScaleButton">
                                <div className="text">
                                    <h4>
                                        <span style={{ position: 'relative' }}>
                                            Добавить шкалу{' '}
                                            <QuestionIcon
                                                className="question-icon"
                                                data-tooltip-id="react-tooltip"
                                                data-tooltip-content="Создайте шкалу, сопоставив количество набранных баллов с уровнем, то есть степенью выраженности или интенсивности измеряемого психологического качества или свойства."
                                                data-tooltip-variant="dark"
                                                style={{ top: '-5px', right: '-22px' }}
                                            />
                                        </span>
                                    </h4>
                                    <p>Небольшое описание шкалы, в одном предложении</p>
                                </div>
                                <div className="plusButton">
                                    <img src={addButton} />
                                </div>
                            </div>

                            {/* {!isThereIntegral && (
                                <div onClick={() => handleCreateScale(true)} className="addScaleButton">
                                    <div className="text">
                                        <h4>Добавить интегральную шкалу</h4>
                                        <p>Небольшое описание шкалы, в одном предложении</p>
                                    </div>
                                    <div className="plusButton">
                                        <img src={addButton} />
                                    </div>
                                </div>
                            )} */}
                        </>
                    ) : (
                        <div style={{ padding: 20, backgroundColor: 'white', textAlign: 'center', fontWeight: 500 }}>
                            <p>Необходимо настроить шкалы</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
