import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { changeLocalFields, updateTestField } from 'Redux/reducers/mainReducer';
import { selectUser } from 'Redux/reducers/userReducer';
import { checkTestFilling } from 'helpers/checkTestFilling';
import { TESTU_LINK } from 'config.js';
import { selectUpdateTestFilling, useTestFilling } from 'store/useTestFilling';

export function GeneralTestCreationLeftMenu() {
    const dispatch = useDispatch();
    const { currentTest } = useSelector((state) => state.main);
    const { user } = useSelector(selectUser);
    const updateTestFilling = useTestFilling(selectUpdateTestFilling);

    const changeAutosave = () => {
        const fields = {
            autosave: !currentTest.autosave,
        };

        dispatch(changeLocalFields(fields));
        dispatch(updateTestField(currentTest._id, fields));
    };

    const changePublicForMe = () => {
        const fields = {
            openOnline: !currentTest.openOnline,
            public: !currentTest.openOnline,
        };

        dispatch(changeLocalFields(fields));
        dispatch(updateTestField(currentTest._id, fields));
    };

    const changePublicForInstitution = () => {
        if (user?.identifier) {
            const institution = currentTest.institution ? '' : user.identifier;

            const fields = {
                institution,
            };
            dispatch(changeLocalFields(fields));
            dispatch(updateTestField(currentTest._id, fields));
        }
    };

    const changePublicForAll = () => {
        if (user?.isAdmin) {
            const fields = {
                openPsychologist: !currentTest.openPsychologist,
            };

            dispatch(changeLocalFields(fields));
            dispatch(updateTestField(currentTest._id, fields));
        }
    };

    const showTestHandler = () => {
        window.open(`${TESTU_LINK}/tests/condition/${currentTest.conditionId}?type=view`, '_blank');
    };

    const handleCheckTestFilling = () => {
        const checkResult = checkTestFilling(currentTest);
        updateTestFilling(checkResult);
    };

    return (
        <div className="leftMenu">
            {user && (
                <>
                    <button onClick={handleCheckTestFilling} className="leftMenuButtonBlue" style={{ marginTop: 0 }}>
                        Проверить заполненность
                    </button>
                    <button className="leftMenuButtonBlue" onClick={showTestHandler}>
                        Смотреть тест
                    </button>

                    <div className="checkBlock" style={{ marginTop: 8, borderTop: 0 }}>
                        <h4>Автоматическое сохранение</h4>
                        <p>Тест будет автоматически сохраняться, при внесении каких-либо изменений</p>
                        <div className="checker">
                            <input onChange={changeAutosave} checked={currentTest.autosave} type="checkbox" />
                            <div className="line"></div>
                            <div className="roundCheck"></div>
                        </div>
                    </div>

                    <div className="checkBlock">
                        <h4>Отображать в testu.online</h4>
                        <p>Тест будет отображаться на сайте testu.online</p>
                        <div className="checker">
                            <input onChange={changePublicForMe} checked={currentTest.openOnline} type="checkbox" />
                            <div className="line"></div>
                            <div className="roundCheck"></div>
                        </div>
                    </div>

                    {user.identifier && (
                        <div className="checkBlock">
                            <h4>Отображать в Организации</h4>
                            <p>Публикация во всех аккаунтах Организации</p>
                            <div className="checker">
                                <input
                                    onChange={changePublicForInstitution}
                                    checked={!!currentTest.institution}
                                    type="checkbox"
                                />
                                <div className="line"></div>
                                <div className="roundCheck"></div>
                            </div>
                        </div>
                    )}

                    {user.isAdmin && (
                        <div className="checkBlock">
                            <h4>Открыть для всех психологов</h4>
                            <p>Тест будет доступен для всех психологов на сайте testu.online</p>
                            <div className="checker">
                                <input
                                    onChange={changePublicForAll}
                                    checked={currentTest.openPsychologist}
                                    type="checkbox"
                                />
                                <div className="line" />
                                <div className="roundCheck" />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
