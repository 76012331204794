export function getPoints(id, scaleId, currentTest, isAdditionalScale = false) {
    let points = 0;

    if (scaleId) {
        const scale = currentTest?.scales?.find(({ _id }) => _id === scaleId);

        if (scale?.levels) {
            scale.levels.forEach((level) => {
                if (level.range.from > points) {
                    points = level.range.from;
                }
                if (level.range.to > points) {
                    points = level.range.to;
                }
            });

            if (currentTest?.settings?.[0]?.countingMethodId === '6290d9bd20e16534e7c09c70') {
                currentTest.questions.forEach((question) => {
                    if (question.typeScale === scaleId) {
                        if (question.selection && question.questionType !== 'MultiSelection') {
                            let reduce = 0;

                            question.selection.forEach((select) => {
                                if (select.value !== null) {
                                    if (select.value > reduce) {
                                        reduce = select.value;
                                    }
                                }
                            });

                            points -= reduce;
                        }
                        if (question.range) {
                            let reduce = 0;
                            if (question.range.from !== null && question.range.from) {
                                if (question.range.from > reduce) {
                                    reduce = question.range.from;
                                }
                            }
                            if (question.range.to !== null) {
                                if (question.range.to > reduce) {
                                    reduce = question.range.to;
                                }
                            }
                            points -= reduce;
                        }
                        if (question.questionType === 'MultiSelection') {
                            let newArray = [];
                            for (let i = 0; i < question.selection.length; i++) {
                                newArray.push(question.selection[i].value);
                            }
                            let sortedArray = newArray.sort((a, b) => a - b);
                            let minus = 0;
                            let toSort = 0;
                            if (sortedArray.length < question.answerQuantity) {
                                toSort = sortedArray.length;
                            } else {
                                toSort = question.answerQuantity;
                            }
                            for (let i = 0; i < toSort; i++) {
                                if (sortedArray[sortedArray.length - 1 - i]) {
                                    minus += sortedArray[sortedArray.length - 1 - i];
                                }
                            }
                            points -= minus;
                        }
                    } else if (question.additionalScales?.length) {
                        if (question.selection && question.questionType !== 'MultiSelection') {
                            let reduce = 0;

                            question.selection.forEach(({ additionalScales }) => {
                                if (additionalScales?.length) {
                                    additionalScales.forEach(({ scale, value }) => {
                                        if (scale === scaleId && value && value > reduce) {
                                            reduce = value;
                                        }
                                    });
                                }
                            });

                            points -= reduce;
                        }
                    }
                });
            } else {
                const question = currentTest.questions[id];

                if (question) {
                    if (!isAdditionalScale) {
                        if (question.selection && question.questionType !== 'MultiSelection') {
                            let value = 0;

                            question.selection.forEach((select) => {
                                if (select.value !== null) {
                                    if (select.value > value) {
                                        value = select.value;
                                    }
                                }
                            });

                            return points - value;
                        }

                        if (question.range) {
                            let value = 0;
                            if (question.range.from !== null && question.range.from) {
                                if (question.range.from > value) {
                                    value = question.range.from;
                                }
                            }
                            if (question.range.to !== null) {
                                if (question.range.to > value) {
                                    value = question.range.to;
                                }
                            }
                            return points - value;
                        }

                        if (question.questionType === 'MultiSelection') {
                            let newArray = [];
                            for (let i = 0; i < question.selection.length; i++) {
                                newArray.push(question.selection[i].value);
                            }
                            let sortedArray = newArray.sort((a, b) => a - b);
                            let minus = 0;
                            let toSort = 0;
                            if (sortedArray.length < question.answerQuantity) {
                                toSort = sortedArray.length;
                            } else {
                                toSort = question.answerQuantity;
                            }
                            for (let i = 0; i < toSort; i++) {
                                if (sortedArray[sortedArray.length - 1 - i]) {
                                    minus += sortedArray[sortedArray.length - 1 - i];
                                }
                            }
                            return points - minus;
                        }
                    } else {
                        if (question.selection && question.questionType !== 'MultiSelection') {
                            let reduce = 0;

                            question.selection.forEach(({ additionalScales }) => {
                                if (additionalScales?.length) {
                                    additionalScales.forEach(({ scale, value }) => {
                                        if (scale === scaleId && value && value > reduce) {
                                            reduce = value;
                                        }
                                    });
                                }
                            });

                            return (points -= reduce);
                        } else if (question.questionType === 'MultiSelection') {
                            let newArray = [];
                            // for (let i = 0; i < question.selection.length; i++) {
                            //     newArray.push(question.selection[i].value);
                            // }

                            question.selection.forEach(({ additionalScales }) => {
                                if (additionalScales?.length) {
                                    additionalScales.forEach(({ scale, value }) => {
                                        if (scale === scaleId) {
                                            newArray.push(value);
                                        }
                                    });
                                }
                            });

                            let sortedArray = newArray.sort((a, b) => a - b);
                            let minus = 0;
                            let toSort = 0;
                            if (sortedArray.length < question.answerQuantity) {
                                toSort = sortedArray.length;
                            } else {
                                toSort = question.answerQuantity;
                            }
                            for (let i = 0; i < toSort; i++) {
                                if (sortedArray[sortedArray.length - 1 - i]) {
                                    minus += sortedArray[sortedArray.length - 1 - i];
                                }
                            }
                            return points - minus;
                        }
                    }
                }
            }
        }
    }

    return points;
}
