import React from "react";
import Header from "../../components/Header/Header";
import bannerImg from "./img/bannerImg.svg"
import "./GeneralPage.css"
import "../../App.css"
import {Link} from "react-router-dom";
import {createTest} from "../../Redux/reducers/mainReducer";
import {useDispatch, useSelector} from 'react-redux'
import {Footer} from "../../components/Footer/Footer";
import { TESTU_LINK } from '../../config'
import {Alert} from '../../components/Alert/Alert'

export function GeneralPage(){
    const { alert } = useSelector(state => state.alert)
    let dispatch = useDispatch();

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    let token = getCookie('token');
   return (
       <div className="generalPage">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            <Header/>
            <div className="content">
                <div className="container minHeight">
                    <div className="banner">
                        <h1>Конструктор тестов.<br/>Создай новый тест или опрос.</h1>
                        <div className="row">
                            {token ?
                                <Link to="/test"><button onClick={() => {dispatch(createTest())}} className="purpleFilled">Создать тест</button></Link>
                                :
                                <a href={`${TESTU_LINK}/psych/login`}><button onClick={() => {dispatch(createTest())}} className="purpleFilled">Создать тест</button></a>
                            }
                            {/*<button className="greyFilled">Как это работает</button>*/}
                        </div>
                        <img className="bannerImg" src={bannerImg}/>

                    </div>
                </div>
                {/*<GeneralRecommendations/>*/}
            </div>
           <Footer/>
       </div>
   )
}