import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import {
    changeLocalScaleDescription,
    changeLocalScaleInterpretation,
    changeLocalScaleRecommendation,
    updateScale,
    updateTestField,
} from 'Redux/reducers/mainReducer';
import infoDot from '../../img/infoError.svg';

export function ScaleTextField({
    currentTestId,
    scaleId,
    currentScaleIndex,
    value,
    label,
    field,
    scaleLevelId,
    currentTestScales,
}) {
    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsError(!value);
    }, [value]);

    const handleChange = (e) => {
        const value = e.target.value;

        if (field === 'description') {
            dispatch(changeLocalScaleDescription(currentScaleIndex, value));
        } else if (field === 'interpretation') {
            dispatch(changeLocalScaleInterpretation(currentScaleIndex, scaleLevelId, value));
        } else if (field === 'recommendation') {
            dispatch(changeLocalScaleRecommendation(currentScaleIndex, scaleLevelId, value));
        }
    };

    const handleChangeBlur = (e) => {
        const value = e.target.value;

        if (value.trim() === '') {
            setIsError(true);
            return;
        }

        const fields = {};

        if (field === 'description') {
            fields.description = value;
            dispatch(updateScale(scaleId, currentTestId, fields));
        } else if (field === 'interpretation') {
            const levels = [...currentTestScales[currentScaleIndex].levels];
            levels[scaleLevelId].interpretation = value;
            fields.levels = levels;
            dispatch(updateScale(scaleId, currentTestId, fields));
        } else if (field === 'recommendation') {
            const levels = [...currentTestScales[currentScaleIndex].levels];
            levels[scaleLevelId].recommendation = value;
            fields.levels = levels;
            dispatch(updateScale(scaleId, currentTestId, fields));
        }

        if (Object.keys(fields).length) {
            dispatch(updateTestField(currentTestId, fields));
        }
    };

    return (
        <div
            onClick={(e) => e.currentTarget.firstElementChild.focus()}
            className={cn('dropdownTextfield', {
                error: isError,
            })}
        >
            <textarea onBlur={handleChangeBlur} value={value} onChange={handleChange} placeholder=" " />
            <label>{label}</label>

            {isError && (
                <img
                    className="infoDot"
                    style={{ right: -30 }}
                    src={infoDot}
                    alt=""
                    data-tooltip-id="react-tooltip"
                    data-tooltip-content="Поле обязательно для заполнения"
                    data-tooltip-variant="error"
                />
            )}
        </div>
    );
}
