import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CreationTabBar from './atoms/CreationTabBar';
import CreationContent from './atoms/CreationContent';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchQuestions,
    fetchScales,
    fetchScaleSettings,
    fetchStaticTags,
    setCurrentTestById,
} from '../../Redux/reducers/mainReducer';
import { showAlert } from '../../Redux/actions/alertActions';
import { getCookie } from '../../helpers/cookies';
import Popup from '../../components/Popup/Popup';
import { Alert } from '../../components/Alert/Alert';
import Header from '../../components/Header/Header';
import { selectResetTestFilling, useTestFilling } from 'store/useTestFilling';
import './testCreation.css';

export default function TestCreation() {
    const dispatch = useDispatch();
    const { alert } = useSelector((state) => state.alert);
    const navigate = useNavigate();
    const tests = useSelector((state) => state.main.tests);
    const params = useParams();
    const resetTestFilling = useTestFilling(selectResetTestFilling);

    useEffect(() => {
        resetTestFilling();
    }, [params.testId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (tests.length && window.location.pathname !== '/test') {
            const id = params.testId;
            let redirectTimeout = null;

            if (id) {
                fetch(`/api/test/check-access/${id}`, {
                    headers: {
                        Authorization: 'Bearer ' + getCookie('token'),
                    },
                }).then((response) => {
                    if (!response.ok) {
                        dispatch(
                            showAlert({
                                type: 'error',
                                text: 'Нет доступа для просмотра и редактирования данного теста',
                            })
                        );
                        redirectTimeout = setTimeout(() => {
                            navigate('/tests');
                        }, 3000);
                    } else {
                        dispatch(setCurrentTestById(id));
                        dispatch(fetchScales(id));
                        dispatch(fetchQuestions(id));
                        dispatch(fetchScaleSettings(id));
                    }
                });
            }

            if (params.questions) {
                dispatch(fetchStaticTags());
            }

            return () => {
                clearTimeout(redirectTimeout);
            };
        }
    }, [tests, dispatch]);
    let [currentWindow, setCurrentWindow] = React.useState(0);

    useEffect(() => {
        if (params.questions) {
            if (params.questions === 'scales') {
                setCurrentWindow(1);
            }

            if (params.questions === 'questions') {
                setCurrentWindow(2);
            }
        }
    }, []);

    return (
        <div className="testCreationHolder">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            <Header />
            <div className="testCreation">
                <div className="container">
                    <CreationTabBar currentWindow={currentWindow} setCurrentWindow={setCurrentWindow} />
                    <CreationContent currentWindow={currentWindow} />
                    <Popup />
                </div>
            </div>
        </div>
    );
}
