import React, { useEffect, useState } from 'react';

import DropdownInput from './DropdownInput';

export default function QuestionItemRange({ currentTest, _id, range, id1 }) {
    const [dots, setDots] = useState([]);

    function removeActive() {
        let dots = document.getElementsByClassName('dot');
        for (let i = 0; i < dots.length; i++) {
            dots[i].classList.remove('active');
        }
    }

    useEffect(() => {
        const tempDots = [];
        let isFirst = true;

        if (range) {
            for (let i = range.from; i <= range.to; i++) {
                tempDots.push(
                    <div
                        className={isFirst ? 'dot active' : 'dot'}
                        onClick={(el) => {
                            removeActive();
                            el.target.classList.add('active');
                        }}
                    >
                        <span>{i}</span>
                    </div>
                );
                if (isFirst) {
                    isFirst = false;
                }
            }
        }

        setDots(tempDots);
    }, [range]);

    return (
        <div>
            <div className="answer row slider">
                <DropdownInput
                    id="linear_from"
                    questionId={_id}
                    currentTest={currentTest}
                    value={range.from}
                    id1={id1}
                    type="SET_LINEAR_FROM"
                    label="Диапазон от"
                />
                <DropdownInput
                    id="linear_to"
                    questionId={_id}
                    currentTest={currentTest}
                    value={range.to}
                    id1={id1}
                    type="SET_LINEAR_TO"
                    label="До"
                />
            </div>
            <div className="slider">
                <div className="dots">{dots}</div>
                <div className="line"></div>
            </div>
        </div>
    );
}
