import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';

import { selectLocalChanges, selectResetLocaleChanges, useTempLocalChanges } from 'store/useTempLocalChanges';
import { updateTestField } from 'Redux/reducers/mainReducer';
import styles from './SaveButton.module.css';

export function SaveButton({ currentTestId }) {
    const dispatch = useDispatch();
    const localChanges = useTempLocalChanges(selectLocalChanges);
    const resetLocalChanges = useTempLocalChanges(selectResetLocaleChanges);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (Object.keys(localChanges).length) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [localChanges]);

    const handleSave = () => {
        if (Object.keys(localChanges).length) {
            dispatch(updateTestField(currentTestId, localChanges));
            resetLocalChanges();
        }
    };

    return createPortal(
        <div className={styles.saveButtonWrapper}>
            <button onClick={handleSave} disabled={isDisabled}>
                Сохранить
            </button>
        </div>,
        document.getElementById('root')
    );
}
