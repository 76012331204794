import { create } from 'zustand';
import { toast } from 'react-toastify';

const initialState = {
    state: {
        main: false,
        scales: [],
    },
};

export const useTestFilling = create((set) => ({
    ...initialState,
    updateTestFilling: ({ main, scales }) => {
        if (main || scales.length) {
            toast.error('В тесте есть незаполненные поля');
            set((state) => ({ state: { ...state.state, main, scales } }));
        } else {
            toast.success('Тест заполнен корректно');
            set({ ...initialState });
        }
    },
    resetTestFilling: () => set({ ...initialState }),
}));

export const selectTestFilling = (state) => state.state;

export const selectUpdateTestFilling = (state) => state.updateTestFilling;

export const selectResetTestFilling = (state) => state.resetTestFilling;
