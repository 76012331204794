import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import {
    changeLocalSpecialistDescription,
    changeLocalStudentDescription,
    changeLocalStudentInstruction,
    updateTestField,
} from 'Redux/reducers/mainReducer';
import { selectUpdateLocaleChanges, useTempLocalChanges } from 'store/useTempLocalChanges';
import infoDot from '../img/infoError.svg';
import { ReactComponent as QuestionIcon } from 'assets/img/question.svg';

const changeLocalFunctions = {
    desTeacher: changeLocalSpecialistDescription,
    desStudent: changeLocalStudentDescription,
    desStudents: changeLocalStudentInstruction,
};

export function TestTextField({ currentTestId, value, label, field, autosave, questionText = '' }) {
    const dispatch = useDispatch();
    const updateLocalChanges = useTempLocalChanges(selectUpdateLocaleChanges);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsError(!value);
    }, [value]);

    const handleChange = (e) => {
        const callback = changeLocalFunctions[field];

        if (callback) {
            dispatch(callback(e.target.value));
        }
    };

    const handleChangeBlur = (e) => {
        const value = e.target.value;

        if (value.trim() === '') {
            setIsError(true);
            return;
        }

        const fields = {
            [field]: value,
        };

        if (autosave) {
            dispatch(updateTestField(currentTestId, fields));
        } else {
            updateLocalChanges(fields);
        }
    };

    return (
        <div
            onClick={(e) => e.currentTarget.firstElementChild.focus()}
            className={cn('dropdownTextfield field-with-tooltip', {
                error: isError,
            })}
        >
            <textarea onBlur={handleChangeBlur} value={value} onChange={handleChange} placeholder=" " />
            <label>
                <span>{label}</span>
                {questionText && (
                    <QuestionIcon
                        data-tooltip-id="react-tooltip"
                        data-tooltip-content={questionText}
                        data-tooltip-variant="dark"
                    />
                )}
            </label>
            {isError && (
                <img
                    className="infoDot"
                    style={{ right: -30 }}
                    src={infoDot}
                    alt=""
                    data-tooltip-id="react-tooltip"
                    data-tooltip-content="Поле обязательно для заполнения"
                    data-tooltip-variant="error"
                />
            )}
        </div>
    );
}
