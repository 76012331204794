import React, {useEffect} from "react";
import HeaderButtons from "./atoms/HeaderButtons";
import './Header.css'
import {Link, useLocation} from "react-router-dom";
import arrowDown from "./img/arrowDown.svg";
import {useSelector} from "react-redux";
import logo from "./img/logo.svg"
import { TESTU_LINK } from '../../config'

export default function Header(){

    const location = useLocation();
    let currentTest = useSelector(state => state.main.currentTest)
    let [isShowed, setIsShowed] = React.useState(false)
    useEffect(() => {
        if(location.pathname === '/test/'+ currentTest._id || location.pathname === '/test' || location.pathname === '/test/' + currentTest._id + '/scales' || location.pathname === '/test/'  + currentTest._id + '/questions'){
            setIsShowed(true);
        }
    }, [location.pathname, currentTest._id])
    return(
        <div className="header">
            <div className="container">
                <a href={TESTU_LINK}>
                    <img alt="" src={logo}/>
                </a>
                {isShowed ? <div className="currentTest">
                    <span>Тест: </span>
                    <p>{currentTest.name}</p>
                    <img alt="" src={arrowDown}/>
                </div> : ''}

                <HeaderButtons/>
            </div>
        </div>
    )
}