import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import {
    changeLocalIntegral,
    changeLocalQuestionQuantity,
    copyQuestion,
    createQuestion,
    deleteQuestion,
    updateLocalQuestionsScales,
    updateQuestion,
} from 'Redux/reducers/mainReducer';
import { showAlert } from 'Redux/actions/alertActions';
import QuestionItem from '../QuestionItem';
import addButton from '../../img/addTest.svg';

const acceptableImageFormats = ['image/png', 'image/jpeg'];

export function QuestionTestCreationRightMenu({ currentQuestionIndex, changeCurrentQuestionIndex }) {
    const dispatch = useDispatch();
    const currentTest = useSelector((state) => state.main.currentTest);
    const [questionItem, setQuestionItem] = useState(null);

    useEffect(() => {
        if (currentTest?.questions?.length && currentTest.questions[currentQuestionIndex]) {
            setQuestionItem(currentTest.questions[currentQuestionIndex]);
        } else {
            setQuestionItem(null);
        }
    }, [currentQuestionIndex, currentTest]);

    function handleQuantityChange(e, id1) {
        dispatch(changeLocalQuestionQuantity(id1, e.target.value));
    }

    function handleQuantityBlur(e, questionId, question) {
        let value = question.selection.length;
        if (e.target.value !== '') {
            value = e.target.value;
        }
        let fields = {
            answerQuantity: value,
        };
        dispatch(updateQuestion(questionId, currentTest._id, fields));
    }

    function integralHandler(questionId, testId, question, questionOrderId) {
        let fields = {
            integral: !question.integral,
        };
        dispatch(changeLocalIntegral(questionOrderId, !question.integral));
        dispatch(updateQuestion(questionId, testId, fields));
    }

    async function copyQuestionCallback(testId, question) {
        await dispatch(copyQuestion(testId, question));
        dispatch(updateLocalQuestionsScales());
        changeCurrentQuestionIndex(currentTest.questions.length);
    }

    function deleteQuestionCallback(testId, question) {
        const prevQuestionIndex = currentQuestionIndex > 0 ? currentQuestionIndex - 1 : 0;
        dispatch(deleteQuestion(testId, question));
        setTimeout(() => {
            dispatch(updateLocalQuestionsScales());
            changeCurrentQuestionIndex(prevQuestionIndex);
        }, 200);
    }

    function addAnswerToQuestion(id, question) {
        const selection = question.selection;
        const answer = {
            question: '',
            value: 0,
            isImage: false,
        };

        if (question.additionalScales?.length) {
            answer.additionalScales = question.additionalScales.map((scale) => ({
                scale,
                value: 0,
            }));
        }

        selection.push(answer);
        dispatch(updateQuestion(id, currentTest._id, { selection }));
    }

    function changeTypeScale(questionId, number, scaleId, questionItem) {
        const fields = {};

        if (questionItem && questionItem.additionalScales) {
            const additionalScales = [...questionItem.additionalScales];
            const selection = [...questionItem.selection].map((item) => {
                item.additionalScales[number] = { scale: scaleId, value: 0 };

                return item;
            });
            additionalScales[number] = scaleId;

            fields['additionalScales'] = additionalScales;
            fields['selection'] = selection;
        } else {
            fields['typeScale'] = scaleId;
        }

        dispatch(updateQuestion(questionId, currentTest._id, fields));
    }

    function addAdditionalScale(questionItem, scaleId) {
        if (scaleId && questionItem.selection) {
            const additionalScales = questionItem?.additionalScales?.length ? [...questionItem.additionalScales] : [];
            const selection = questionItem.selection.map((item) => {
                const additionalScales = item.additionalScales;

                additionalScales.push({
                    scale: scaleId,
                    value: 0,
                });

                return {
                    ...item,
                    additionalScales,
                };
            });

            additionalScales.push(scaleId);

            dispatch(
                updateQuestion(questionItem._id, currentTest._id, {
                    additionalScales,
                    selection,
                })
            );
        }
    }

    function deleteAdditionalScale(questionItem, scaleIndex) {
        const additionalScales = [...questionItem.additionalScales];
        const selection = questionItem.selection.map((item) => {
            const additionalScales = item.additionalScales;

            additionalScales.splice(scaleIndex, 1);

            return {
                ...item,
                additionalScales,
            };
        });

        additionalScales.splice(scaleIndex, 1);

        dispatch(
            updateQuestion(questionItem._id, currentTest._id, {
                additionalScales,
                selection,
            })
        );
    }

    function changeQuestionType(id, type) {
        let fields;

        if (type === 'range') {
            fields = {
                range: {
                    from: '',
                    to: '',
                },
                selection: null,
                questionType: 'Range',
                answerQuantity: 0,
                additionalScales: [],
            };
        } else if (type === 'selection') {
            fields = {
                selection: [],
                range: null,
                questionType: 'Selection',
                answerQuantity: 0,
            };
        } else if (type === 'multiSelection') {
            fields = {
                selection: [],
                range: null,
                questionType: 'MultiSelection',
            };
        } else if (type === 'text') {
            fields = {
                selection: null,
                range: null,
                questionType: 'Text',
            };
        } else {
            return;
        }

        dispatch(updateQuestion(id, currentTest._id, fields));
    }

    const changeImage = (questionId, image) => {
        dispatch(
            updateQuestion(questionId, currentTest._id, {
                image,
            })
        );
    };

    const changeAnswerImage = (questionId, id1, id2, image) => {
        const selection = currentTest.questions[id1].selection;
        selection[id2].image = image;

        dispatch(
            updateQuestion(questionId, currentTest._id, {
                selection,
            })
        );
    };

    const uploadQuestionImage = async (e, questionId) => {
        try {
            const file = e.target.files[0];

            if (!acceptableImageFormats.includes(file.type)) {
                dispatch(showAlert({ type: 'error', text: 'Некорректный формат картинки' }));
            } else if (Math.floor(file.size / 1024) > 1024) {
                dispatch(showAlert({ type: 'error', text: 'Картинка не должна быть больше 1 Мб' }));
            } else {
                const formData = new FormData();
                formData.append('file', file);

                const { data } = await axios.post(`/api/images/${questionId}?type=questions`, formData);

                changeImage(questionId, data.filePath);
            }
        } catch (err) {
            dispatch(showAlert({ type: 'error', text: 'Ошибка загруки картинки' }));
        }
    };

    const deleteQuestionImage = async (questionId, image) => {
        try {
            await axios.delete(`/api/images/${questionId}?image=${image}`);
        } catch (err) {
            console.log(err);
        } finally {
            changeImage(questionId, '');
        }
    };

    const uploadAnswerImage = async (e, questionId, id1, id2) => {
        try {
            const file = e.target.files[0];

            if (!acceptableImageFormats.includes(file.type)) {
                dispatch(showAlert({ type: 'error', text: 'Некорректный формат картинки' }));
            } else if (Math.floor(file.size / 1024) > 1024) {
                dispatch(showAlert({ type: 'error', text: 'Картинка не должна быть больше 1 Мб' }));
            } else {
                const formData = new FormData();
                formData.append('file', file);

                const { data } = await axios.post(`/api/images/${questionId}?type=answers`, formData);

                changeAnswerImage(questionId, id1, id2, data.filePath);
            }
        } catch (err) {
            console.log(err);
            dispatch(showAlert({ type: 'error', text: 'Ошибка загруки картинки' }));
        }
    };

    const deleteAnswerImage = async (questionId, id1, id2, image) => {
        try {
            await axios.delete(`/api/images/${questionId}?image=${image}`);
        } catch (err) {
            console.log(err);
        } finally {
            changeAnswerImage(questionId, id1, id2, '');
        }
    };

    const changeAnswerType = (questionId, selection, index, isImage) => {
        selection[index].isImage = isImage;

        dispatch(
            updateQuestion(questionId, currentTest._id, {
                selection,
            })
        );
    };

    const handleCreateQuestion = async () => {
        await dispatch(createQuestion(currentTest._id));
        changeCurrentQuestionIndex(currentTest.questions.length);
    };

    return (
        <div className="rightMenu">
            {questionItem && (
                <QuestionItem
                    item={questionItem}
                    id1={currentQuestionIndex}
                    currentTest={currentTest}
                    integralHandler={integralHandler}
                    handleQuantityChange={handleQuantityChange}
                    handleQuantityBlur={handleQuantityBlur}
                    deleteQuestionCallback={deleteQuestionCallback}
                    copyQuestionCallback={copyQuestionCallback}
                    changeTypeScale={changeTypeScale}
                    changeQuestionType={changeQuestionType}
                    addAnswerToQuestion={addAnswerToQuestion}
                    uploadQuestionImage={uploadQuestionImage}
                    deleteQuestionImage={deleteQuestionImage}
                    uploadAnswerImage={uploadAnswerImage}
                    deleteAnswerImage={deleteAnswerImage}
                    changeAnswerType={changeAnswerType}
                    addAdditionalScale={addAdditionalScale}
                    deleteAdditionalScale={deleteAdditionalScale}
                />
            )}
            <div onClick={handleCreateQuestion} className="addNewScaleButton addNewQuestionButton">
                <img src={addButton} alt="" />
                <div className="addNewScaleButtonDropdown">
                    <div className="button">
                        <div className="text">
                            <p>Добавить шкалу</p>
                            <label>Небольшое описание шкалы, в одном предложении</label>
                        </div>
                        <div className="plus">
                            <img src={addButton} alt="" />
                        </div>
                    </div>
                    <div className="button">
                        <div className="text">
                            <p>Добавить интегральную шкалу</p>
                            <label>Небольшое описание шкалы, в одном предложении</label>
                        </div>
                        <div className="plus">
                            <img src={addButton} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
