import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ marginTop: 50, textAlign: 'center' }}>
                    <h2>Ошибка в работе приложения</h2>
                    <p style={{ marginTop: 20 }}>Попробуйте обновить страницу или обратитесь в службу поддержки</p>
                    <button onClick={() => window.location.reload()} style={{ marginTop: 20, padding: 5 }}>
                        Обновить страницу
                    </button>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
