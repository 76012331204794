export const selectStyles = {
    control: (base) => ({
        ...base,
        padding: 0,
        borderRadius: 0,
        border: 0,
        borderBottom: '1px solid #d3dade',
        color: '#d3dade',
        fontWeight: 500,
        letterSpacing: '0.3px',
        lineHeight: '20px',
        boxShadow: 'none',
        '&:hover': {},
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'rgba(18,36,67,0.4)',
    }),
};
